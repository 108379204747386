import React from "react";
import Logo from "../../assets/WAYLogo.png";

export function VaaradhiFooter() {
  return (
    <section className="relative overflow-hidden pt-8 lg:pt-10">
      <div className="relative z-10 mx-auto max-w-7xl px-4">
        <div className="-m-6 flex flex-wrap">
          <div className="w-full p-6 md:w-1/2 lg:w-5/12">
            <div className="flex h-full flex-col justify-between">
              <div className="mb-4 inline-flex items-center">
                <img src={Logo} alt="Vaaradhi Logo" className="h-10 w-10" />
                <span className="ml-4 text-2xl lg:text-3xl font-bold">
                  Vaaradhi
                </span>
              </div>
              <div>
                <p className="mb-4 text-base font-medium">
                  A project by an NGO
                </p>
                <p className="text-sm text-black">
                  &copy; {new Date().getFullYear()} by{" "}
                  <a
                    href="https://wearewithyouct.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-bold"
                  >
                    We Are with You Charitable Trust.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full px-6 py-3 lg:p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-3 lg:mb-9 text-xs font-semibold uppercase text-black">
                Organization
              </h3>
              <ul className="text-sm lg:text-base lg:font-medium text-black">
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://wearewithyouct.org/about-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About
                  </a>
                </li>
                {/* <li className="mb-2 lg:mb-4">
                  <a href="https://wearewithyouct.org">Services</a>
                </li> */}
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://wearewithyouct.org/project-vaaradhi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Projects
                  </a>
                </li>
                <li>
                  <a
                    href="https://wearewithyouct.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full px-6 py-3 lg:p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-3 lg:mb-9 text-xs font-semibold uppercase text-black">
                Support
              </h3>
              <ul className="text-sm lg:text-base lg:font-medium text-black">
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://wearewithyouct.org/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://wearewithyouct.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FAQs
                  </a>
                </li>
                {/* <li className="mb-2 lg:mb-4">
                  <a
                    href="https://wearewithyouct.org/donation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Donations
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://wearewithyouct.org/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Volunteer
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full px-6 py-3 lg:p-6 md:w-1/2 lg:w-3/12">
            <div className="h-full">
              <h3 className="tracking-px mb-3 lg:mb-9 text-xs font-semibold uppercase text-black">
                Connect
              </h3>
              <ul className="text-sm lg:text-base lg:font-medium text-black">
                {/* <li className="mb-2 lg:mb-4">
                  <a
                    href="https://www.facebook.com/wearewithyouct"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li> */}
                {/* <li className="mb-2 lg:mb-4">
                  <a
                    href="www.wearewithyouct.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li> */}
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://www.youtube.com/@WeArewithYouCT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                </li>
                <li className="mb-2 lg:mb-4">
                  <a
                    href="https://www.linkedin.com/company/wearewithyouct"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/wearewithyouct"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blueTheme py-3 mt-8 lg:mt-9 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center">
            <p className="text-white text-sm">
              Made with 🤍 by{" "}
              <a
                href="https://twitter.com/TangledDevs"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-bold hover:underline"
              >
                Tangled Devs
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
