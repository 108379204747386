import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { getSpendings } from "../features/common/commonSlice";
import { formatDate } from "../utils/formatDate";
import { MdDelete } from "react-icons/md";
import {
  deleteSpending,
  getSpendingsAdmin,
} from "../features/admin/adminSlice";
import { useLocation } from "react-router-dom";
import { getSpendingsDonor } from "../features/donor/donorSlice";
import { getSpendingsCaretaker } from "../features/caretaker/caretakerSlice";

const Spendings = ({ userType }) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { spendings } = useSelector((store) => store[userType]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userType === "admin") dispatch(getSpendingsAdmin({ id }));
    if (userType === "donor") dispatch(getSpendingsDonor({ id }));
    if (userType === "caretaker") dispatch(getSpendingsCaretaker({ id }));
  }, [dispatch, pathname, id, userType]);
  return (
    <div className="w-full py-12 px-4 md:px-16 ">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl md:text-3xl font-medium capitalize">
          Spendings
        </h1>
        {userType === "admin" && (
          <div>
            <input
              type="button"
              name="addspending"
              value="Add"
              className="bg-blue-500 rounded-md p-2 md:px-4 py-2 text-white font-bold cursor-pointer hover:scale-105"
              onClick={() => {
                navigate(`/admin/students/${id}/spending`);
              }}
            />
          </div>
        )}
      </div>
      {/* displaying spendings in the form of a table */}
      {spendings.length !== 0 && (
        <div className="md:w-full w-full mx-auto overflow-auto flex items-center justify-center p-6">
          <table className="table-auto w-3/4 text-left whitespace-no-wrap">
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-bold text-gray-900 text-md bg-gray-100">
                S.No
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-bold text-gray-900 text-md bg-gray-100">
                Title
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-bold text-gray-900 text-md bg-gray-100">
                Amount
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-bold text-gray-900 text-md bg-gray-100">
                Date
              </th>
            </tr>
            {spendings.map((spending, index) => {
              return (
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {index + 1}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {spending.title}
                  </td>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    {spending.amount}
                  </td>
                  <td className="border-t-2 border-gray-200">
                    {formatDate(spending.date)}
                  </td>
                  {userType === "admin" && (
                    <td
                      className="border-t-2 border-gray-200 px-4 py-3 text-3xl cursor-pointer hover:text-red-600"
                      onClick={() =>
                        dispatch(deleteSpending({ id: spending._id }))
                      }
                    >
                      <MdDelete />
                    </td>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      )}
      {spendings?.length === 0 && (
        <div className="flex items-center justify-center">
          <h2 className="text-xl font-medium capitalize">No Spendings</h2>
        </div>
      )}
    </div>
  );
};

export default Spendings;
