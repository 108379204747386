import React, { useEffect } from "react";
import { StudentsContainer } from "../../components";
import { useDispatch } from "react-redux";
import { getStudents } from "../../features/donor/donorSlice";
const Welcome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudents());
  }, [dispatch]);

  return (
    <div className="pt-16 mt-10 pb-4">
      <h1 className="text-2xl font-semibold text-center">My Students</h1>
      <StudentsContainer userType="donor" />
    </div>
  );
};

export default Welcome;
