import React from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaChild,
  FaHandHoldingHeart,
  FaUserFriends,
} from "react-icons/fa";
import { SiGooglemessages } from "react-icons/si";
import { MdPersonAddAlt1 } from "react-icons/md";
import { BiCalendarCheck } from "react-icons/bi";

const Sidebar = () => {
  return (
    <aside className="sidebar hidden pt-20 w-64 fixed inset-y-0 overflow-x-hidden overflow-y-auto bg-white lg:block">
      <ul className="text-gray-900 py-6 px-7">
        <Link to="/admin" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <FaHome size={20} className="mr-3" />
            Home
          </li>
        </Link>

        <Link to="/admin/students" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <FaChild size={20} className="mr-3" />
            Students
          </li>
        </Link>

        <Link to="/admin/donors" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <FaHandHoldingHeart size={20} className="mr-3" />
            Donors
          </li>
        </Link>

        <Link to="/admin/caretakers" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <FaUserFriends size={20} className="mr-3" />
            Caretakers
          </li>
        </Link>

        <Link to="/admin/groupActivity" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <SiGooglemessages size={20} className="mr-3" />
            Group Activity
          </li>
        </Link>

        <Link to="/admin/addAdmin" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <MdPersonAddAlt1 size={20} className="mr-3" />
            Add Admin
          </li>
        </Link>

        <Link to="/admin/addEvent" className="font-bold">
          <li className="my-3 hover:bg-slate-300 rounded-md px-4 py-2 flex items-center">
            <BiCalendarCheck size={20} className="mr-3" />
            Add Event
          </li>
        </Link>
      </ul>
    </aside>
  );
};

export default Sidebar;
