import React, { useEffect } from "react";
import { SplideSlide, Splide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, getEvents } from "../features/admin/adminSlice";
import BackDrop from "../utils/BackDrop";
import { formatDate } from "../utils/formatDate";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {HiMapPin} from "react-icons/hi2"
const splideOptions = {
  perPage: 3,
  perMove: 1,
  type: "slide",
  rewind: false,
  keyboard: "global",
  gap: "1rem",
  pagination: false,
  padding: "",
  breakpoints: {
    1200: { perPage: 6 },
    992: { perPage: 3 },
    768: { perPage: 2 },
    480: { perPage: 1 },
  },
};

const Events = () => {
  const { events } = useSelector((store) => store["admin"]);
  const { isLoading } = useSelector((store) => store["admin"]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  return (
    <div>
      {events.length > 0 && (
        <Splide options={splideOptions}>
          {events.map((event) => {
            return (
              <SplideSlide key={event._id} className="mb-0.5">
                <div className="bg-white p-6 rounded-md flex flex-col gap-y-2">
                  {/* Title */}
                  <div className="flex justify-between font-bold text-md md:text-lg">
                    {event.title}
                    <MdDelete
                      className="text-2xl cursor-pointer hover:text-red-500 hover:scale-105"
                      onClick={() => dispatch(deleteEvent({ id: event._id }))}
                    />
                  </div>
                  {/* Description */}
                  
                  <div>{event.description}</div>
                  <div className="flex flex-col md:flex-row justify-between md:mt-4 gap-y-2">
                    {/* venue */}
                    <div className="uppercase flex gap-x-2">
                      <HiMapPin size={20}/>
                      <p>{event.venue}</p>
                    </div>
                    {/* Date */}
                    <div className="flex gap-x-2">
                      <FaRegCalendarAlt size={20} />
                      {formatDate(event.date)}
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      )}
			{
				events.length === 0 && <div className="text-center font-semibold">
					No Upcoming Events
				</div>
			}
      <BackDrop open={isLoading} />
    </div>
  );
};

export default Events;
