import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar } from "../../components";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import { addEvent } from "../../features/admin/adminSlice";
import { useNavigate } from "react-router-dom";

const AddEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store["admin"]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    venue: "",
    date: "",
  });

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(formData);
    const response = await dispatch(addEvent(formData));
    if(response.payload.status === "success")
    {
      setTimeout(() => {
        navigate(-1,{replace : true})
      }, 1000);
    }
    console.log(response);
  };
  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
        <h2 className="mt-10 text-center font-medium text-2xl text-gray-800">
          Add Event
        </h2>
        <div className="mt-5">
          <form
            className="flex flex-col bg-white p-8 rounded-lg shadow-md w-4/5 md:w-3/5 m-auto"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col py-4">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                id="title"
                className="bg-slate-200 px-2 h-10 rounded-md"
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col py-4">
              <label htmlFor="description">Description</label>
              <textarea
                type="text"
                name="description"
                id="description"
                rows="2"
                className="bg-slate-200 p-2 rounded-md"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10">
              <div className="flex flex-col py-4">
                <label htmlFor="title">Venue</label>
                <input
                  type="text"
                  name="venue"
                  id="venue"
                  className="bg-slate-200 px-2 h-10 rounded-md"
                  value={formData.venue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col py-4">
                <label htmlFor="title">Event Date</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="bg-slate-200 px-2 h-10 rounded-md"
                  value={formData.date}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <button
                type="submit"
                className="bg-blue-500 w-52 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Add upcoming Event
              </button>
            </div>
          </form>
        </div>
      </section>
      <BackDrop open={isLoading} />
      <ToastContainer />
    </section>
  );
};

export default AddEvent;
