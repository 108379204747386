import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProfile } from "../../features/admin/adminSlice";
import BackDrop from "../../utils/BackDrop";
import { toast } from "react-hot-toast";

const AdminProfile = () => {
  const user = useSelector((store) => store["admin"].user);
  const isLoading = useSelector((store) => store["admin"].isLoading);
  const [name, setName] = useState(user.name);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(user.image);
  // const [dateOfBirth, setDateOfBirth] = useState(formatDate(user.dateOfBirth));
  const [email, setEmail] = useState(user.email);
  const [mobile, setMobile] = useState(user.mobile);
  // const [occupation, setOccupation] = useState(user.occupation);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();
  const message = useSelector((store) => store["admin"].message);
  console.log(message);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await dispatch(editProfile({ name, email, mobile }));
      setIsEditing(false);
      // window.location.reload();
    } catch (error) {
      toast.error("Something went wrong !")
    }
  };

  return (
    <>
      <div className="max-w-2xl mx-auto py-32">
        <div className="flex items-center justify-between mx-5 md:mx-auto mb-8">
          <h1 className="text-3xl font-bold">My Profile</h1>
          {isEditing ? (
            <button
              className="bg-blueTheme text-white font-bold py-2 px-4 rounded"
              onClick={handleSave}
            >
              Save
            </button>
          ) : (
            <button
              className="bg-blueTheme text-white font-bold py-2 px-4 rounded"
              onClick={handleEdit}
            >
              Edit Profile
            </button>
          )}
        </div>
        <div className="flex justify-center items-center flex-col md:flex-row">
          <div className="sm:w-1/2 mb-8">
            <img
              src={image}
              alt="Profile"
              className="rounded-full w-44 h-44 object-cover"
            />
          </div>
          <div className="sm:w-1/2 px-4">
            <div className="mb-4">
              <label
                className="inline-block font-bold mb-2 mr-1"
                htmlFor="Name"
              >
                Name:
              </label>
              {isEditing ? (
                <input
                  className="inline-block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              ) : (
                <p className="inline-block">{name}</p>
              )}
            </div>
            {/* <div className="mb-4">
              <label
                className="inline-block mr-1 font-bold mb-2"
                htmlFor="dateOfBirth"
              >
                Date of Birth:
              </label>
              {isEditing ? (
                <input
                  className="inline-block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="dateOfBirth"
                  type="date"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              ) : (
                <p className="inline-block">{dateOfBirth}</p>
              )}
            </div> */}
            <div className="mb-4">
              <label
                className="inline-block mr-1 font-bold mb-2"
                htmlFor="email"
              >
                Email:
              </label>
              {isEditing ? (
                <input
                  className="inline-block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <p className="inline-block">{email}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="inline-block mr-1 font-bold mb-2"
                htmlFor="mobile"
              >
                Mobile:
              </label>
              {isEditing ? (
                <input
                  className="inline-block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="mobile"
                  type="tel"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              ) : (
                <p className="inline-block">{mobile}</p>
              )}
            </div>
            {/* <div className="mb-4">
              <label
                className="inline-block mr-1 font-bold mb-2"
                htmlFor="occupation"
              >
                Occupation:
              </label>
              {isEditing ? (
                <input
                  className="inline-block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="occupation"
                  type="text"
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                />
              ) : (
                <p className="inline-block">{occupation}</p>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {/* <section className="flex items-center justify-center">
        <div className="w-full">
          <h2 className="font-bold text-2xl text-center">My Students</h2>
          <StudentsContainer userType={"donor"} />
        </div>
      </section> */}
      <BackDrop open={isLoading}></BackDrop>
    </>
  );
};

export default AdminProfile;
