import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/url";
import { toast } from "react-hot-toast";
const user = localStorage.getItem("user");
const token = localStorage.getItem("token");

const initialState = {
  user: user ? JSON.parse(user) : null,
  token: token,
  students: [],
  isLoading: false,
  currentStudent: {
    activities: [],
  },
  studentActivities: [],
  currentActivity: {},
  spendings : [],
  academicRecords : [],
  activityPhotos: [],
};

export const caretakerLogin = createAsyncThunk(
  "caretaker/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/caretaker/login`,
        payload
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudents = createAsyncThunk(
  "caretaker/students",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/caretaker/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudent = createAsyncThunk(
  "caretaker/student/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/student/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityCaretaker = createAsyncThunk(
  "caretaker/activity/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/activity/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityPhotos = createAsyncThunk(
  `${BASE_URL}api/caretaker/activity/:id`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/activity/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addActivity = createAsyncThunk(
  `api/caretaker/students/:id/addActivity`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/caretaker/students/${payload.student}/addActivity`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editActivity = createAsyncThunk(
  "api/caretaker/activity/:id(patch)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/caretaker/activity/${payload.activityId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudentActivitiesCaretaker = createAsyncThunk(
  "api/caretaker/student/:id/activities(get)",
  async (payload, { rejectWithValue }) => {
    const studentId = payload.id;
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/student/${studentId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "api/caretaker/profile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/caretaker/profile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadStudentActivity = createAsyncThunk(
  "upload/studentActivities",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/studentActivities`,
        payload.formDataPhotos,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSpendingsCaretaker = createAsyncThunk(
  "students/:studentId/spending(Get)caretaker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/students/${payload.id}/spending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAcademicRecordsCaretaker = createAsyncThunk(
  "academicRecordscaretaker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/caretaker/students/${payload.id}/academicRecord`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const caretakerSlice = createSlice({
  name: "caretaker",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(caretakerLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(caretakerLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { caretakerData, token } = payload;
      localStorage.setItem("user", JSON.stringify(caretakerData));
      localStorage.setItem("token", token);
      state.user = caretakerData;
      state.token = token;
      toast.success("Login Successful");
    });
    builder.addCase(caretakerLogin.rejected, (state) => {
      state.isLoading = false;
      toast.error("Invalid credentials!");
    });
    builder.addCase(getStudents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { data } = payload;
      state.students = data;
    });

    builder.addCase(getStudents.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentStudent = payload.student;
      state.studentActivities = state.currentStudent.activities;
    });

    builder.addCase(getStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error( "asohflkawjsnd");
    
    });

    builder.addCase(getActivityCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getActivityCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentActivity = payload.data;
      state.activityPhotos = state.currentActivity.photos;
    });

    builder.addCase(getActivityCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(addActivity.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addActivity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      // const addedActivity = payload;
      // state.studentActivities = [...state.studentActivities, addedActivity];
      // state.currentStudent = {
      //   ...state.currentStudent,
      //   activities: [...state.currentStudent.activities, addedActivity],
      // };
      // state.studentActivities.push(addedActivity);
      // state.currentStudent.activities.push(addedActivity);
      toast.success("Activity Added !");
    });

    builder.addCase(addActivity.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(editActivity.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(editActivity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Edit activity Successful!");
    });

    builder.addCase(editActivity.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getStudentActivitiesCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getStudentActivitiesCaretaker.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.studentActivities = payload.data;
      }
    );

    builder.addCase(
      getStudentActivitiesCaretaker.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        toast.error("Something went wrong");
      }
    );

    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(editProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const updatedDetails = payload.updatedCaretaker;
      localStorage.setItem("user", JSON.stringify(updatedDetails));
      state.message = payload.message;
      state.user = updatedDetails;
      toast.success("Details Updated !");
    });

    builder.addCase(editProfile.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadStudentActivity.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadStudentActivity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Photos uploaded");
    });

    builder.addCase(uploadStudentActivity.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getSpendingsCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSpendingsCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.spendings = payload.data;
    });

    builder.addCase(getSpendingsCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getAcademicRecordsCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAcademicRecordsCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.academicRecords = payload.academicRecords;
    });

    builder.addCase(getAcademicRecordsCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });
  },
});

export default caretakerSlice.reducer;
