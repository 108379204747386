import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  getDonor,
  removeDonor,
  makeDonorActive,
  makeDonorInactive,
} from "../../features/admin/adminSlice";
import BackDrop from "../../utils/BackDrop";
import StudentsContainer from "../../components/StudentsContainer";

const Donor = ({ userType }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentDonor } = useSelector((store) => store[userType]);
  const { isLoading } = useSelector((store) => store[userType]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userType === "admin") {
      dispatch(getDonor({ id }));
    }
  }, [dispatch, id, userType, pathname]);

  const dob = currentDonor.dateOfBirth;
  const date = new Date(dob);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Note: January is 0 in JavaScript, so we need to add 1
  const year = date.getFullYear();
  const dateOfBirth = day + "-" + month + "-" + year;

  const handleRemoveDonor = async () => {
    const response = await dispatch(removeDonor({ id: currentDonor?._id }));
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };

  const handleDonorInActive = async () => {
    const response = await dispatch(
      makeDonorInactive({ id: currentDonor?._id })
    );
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };

  const handleDonorStatus = async () => {
    const response = await dispatch(makeDonorActive({ id: currentDonor?._id }));
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };

  return (
    <div className="py-28">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6">
        <div className="flex flex-col items-center justify-center gap-4">
          <img
            src={currentDonor.image}
            alt="student"
            className="rounded-full h-52 w-52"
          />
          <div className="flex flex-col gap-2">
            {currentDonor?.isActive ? (
              <button
                type="button"
                onClick={handleDonorInActive}
                className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-yellow-400 hover:bg-yellow-500 focus:outline-none shadow-md"
              >
                Make Inactive
              </button>
            ) : (
              <button
                type="button"
                onClick={handleDonorStatus}
                className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-blue-500 hover:bg-blue-600 focus:outline-none shadow-md"
              >
                Make Active
              </button>
            )}
            <button
              type="button"
              onClick={handleRemoveDonor}
              className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-red-500 hover:bg-red-600 focus:outline-none shadow-md"
            >
              Remove Donor
            </button>
          </div>
        </div>
        <div className="p-4">
          <h3 className="font-bold text-start text-xl md:text-2xl mb-4 md:mb-6">
            Donor Details
          </h3>
          <div className="flex justify-start">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center">
                <label htmlFor="donorName" className="font-semibold text-xl">
                  Name:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.name}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="donorDOB" className="font-semibold text-xl">
                  DOB:&nbsp;
                </label>
                <p className="text-lg font-medium">{dateOfBirth}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="font-semibold text-xl">
                  Email:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.email}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="mobile" className="font-semibold text-xl">
                  Mobile:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.mobile}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="pan" className="font-semibold text-xl">
                  Pan:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.pan}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="occupation" className="font-semibold text-xl">
                  Occupation:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.occupation}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="pan number" className="font-semibold text-xl">
                  Pan Number:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentDonor.pan}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="flex items-center mt-4 md:mt-10 justify-center">
        <div className="w-full">
          <h2 className="font-bold text-center text-2xl">Students</h2>
          <StudentsContainer userType={"admin"} />
        </div>
      </section>
      <BackDrop open={isLoading}></BackDrop>
    </div>
  );
};

export default Donor;
