import React, { useState, useEffect } from "react";
import LoginImg from "../assets/waylogo.webp";
import { adminLogin } from "../features/admin/adminSlice";
import { donorLogin } from "../features/donor/donorSlice";
import { caretakerLogin } from "../features/caretaker/caretakerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackDrop from "../utils/BackDrop";


const Login = () => {
  const dispatch = useDispatch();
  const { userType } = useParams();
  const { user } = useSelector((store) => store[userType]);
  const navigate = useNavigate();
  const isLoading = useSelector((store) => store[userType].isLoading);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert("Fill all the details");
      return;
    }
    if (userType === "admin") {
      dispatch(adminLogin({ email, password }));
    } else if (userType === "donor") {
      dispatch(donorLogin({ email, password }));
    } else if (userType === "caretaker") {
      dispatch(caretakerLogin({ email, password }));
    }
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate(`/${userType}`, { replace: true });
      }, 1000);
    }
  }, [user, navigate, userType]);

  return (
    <div className="w-screen flex flex-col items-center justify-center min-h-screen bg-gray-50 overflow-x-hidden">
      <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
        <div className="mb-6">
          <img className="mx-auto w-64" src={LoginImg} alt="logo" />
        </div>
        <form
          className="bg-white rounded-lg shadow-lg px-8 pt-6 pb-8 mb-4"
          action=""
        >
          <h2 className="text-blueTheme uppercase text-center text-2xl font-bold">
            {`${userType} login`}
          </h2>
          <div className="mt-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter your email"
            />
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter your password"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              onClick={(e) => onSubmit(e)}
              className="bg-blueTheme text-white w-full py-2 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      <BackDrop open={isLoading}></BackDrop>
    </div>
  );
};

export default Login;
