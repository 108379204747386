import React, { useState } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { BsPersonFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import way from "../assets/WAYLogo.png";
import { useSelector } from "react-redux";
import {
  FaHome,
  FaChild,
  FaHandHoldingHeart,
  FaUserFriends,
} from "react-icons/fa";
import { MdPersonAddAlt1 } from "react-icons/md";
import { BiCalendarCheck } from "react-icons/bi";
import { SiGooglemessages } from "react-icons/si";
import { toast } from "react-hot-toast";

const Navbar = ({ role }) => {
  const user = useSelector((store) => store[role].user);
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [isMenuOpen, toggleMenu] = useState(false);

  // const handleClick = () => {
  //   setShowOptions(!showOptions);
  // };
  const handleMenu = () => {
    toggleMenu(!isMenuOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    toast.success("Logged out successfully!");
    window.location.reload();
    navigate("/", { replace: true });
  };

  return (
    <>
      <nav className="navbar lg:px-16 fixed top-0 inset-x-0 shadow-lg z-50 bg-white flex items-center justify-between p-4 gap-2">
        <div className="name flex justify-center items-center">
          <Link to={`/${role}`}>
            <img
              src={way}
              alt="WAY"
              className="w-12 h-12 rounded-full cursor-pointer "
            />
          </Link>
          <p className="text-black font-medium text-xl ml-4">
            Welcome{" "}
            <span className=" font-bold text-blueTheme">
              {user.name.split(" ")[0]}
            </span>
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="profile flex justify-end flex-col items-center relative">
            <img
              src={user.image}
              alt="Profile"
              className="w-10 h-10 rounded-full cursor-pointer"
              onClick={() => setShowOptions(!showOptions)}
            />
            {showOptions && (
              <div className="absolute top-12 right-0 w-32 bg-white shadow-2xl rounded-md p-2">
                {/* {role === "admin" && (
                  <button
                    className="flex justify-center items-center w-full text-left hover:bg-slate-300 rounded-md px-4 py-1"
                    onClick={() => {
                      infoToast("In progress");
                      setShowOptions(false);
                    }}
                  >
                    <BsPersonFill className=" mr-2" />
                    <Link to={"/admin"}>Profile</Link>
                  </button>
                )} */}

                <button
                  className="flex justify-center items-center w-full text-left hover:bg-slate-300 rounded-md px-4 py-1"
                  onClick={() => {
                    navigate(`/${role}/profile`);
                    setShowOptions(false);
                  }}
                >
                  <BsPersonFill className=" mr-2" />
                  <Link to={`/${role}/profile`}>Profile</Link>
                </button>
                <button
                  onClick={handleLogout}
                  className="flex justify-center items-center w-full text-left hover:bg-slate-300 rounded-md px-4 py-1"
                >
                  <FiLogOut className="mr-2" />
                  <Link to={"/"}>Logout</Link>
                </button>
              </div>
            )}
          </div>
          {role === "admin" ? (
            <div
              onClick={handleMenu}
              className="text-3xl cursor-pointer lg:hidden"
            >
              <HiOutlineMenu />
            </div>
          ) : null}
          {role === "admin"
            ? isMenuOpen && (
                <div className="absolute right-0 top-16 w-full ">
                  <ul className="flex flex-col justify-end border-black items-center bg-white text-white lg:hidden p-4 mt-4 border rounded-md ">
                    <li
                      className="my-3 text-black border-b border-black px-4 py-2 flex"
                      onClick={handleMenu}
                    >
                      <FaHome size={20} className="mr-3" />
                      <Link to="/admin" className="font-bold">
                        Home
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300 px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <FaChild size={20} className="mr-3" />
                      <Link to="/admin/students" className="font-bold">
                        Students
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300  px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <FaHandHoldingHeart size={20} className="mr-3" />
                      <Link to="/admin/donors" className="font-bold">
                        Donors
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300  px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <FaUserFriends size={20} className="mr-3" />
                      <Link to="/admin/caretakers" className="font-bold">
                        Caretakers
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300  px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <SiGooglemessages size={20} className="mr-3" />
                      <Link to="/admin/groupActivity" className="font-bold">
                        Group Activity
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300 px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <MdPersonAddAlt1 size={20} className="mr-3" />
                      <Link to="/admin/addAdmin" className="font-bold">
                        Add Admin
                      </Link>
                    </li>
                    <li
                      className="my-3 text-black border-b border-black hover:bg-slate-300 px-4 py-2 flex items-center"
                      onClick={handleMenu}
                    >
                      <BiCalendarCheck size={20} className="mr-3" />
                      <Link to="/admin/addEvent" className="font-bold">
                        Add Event
                      </Link>
                    </li>
                  </ul>
                </div>
              )
            : null}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
