import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  editActivity
} from "../../features/caretaker/caretakerSlice";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadToCloud } from "../../features/common/commonSlice";


const EditActivity = ({ userType }) => {
  const navigate = useNavigate();
  const {isLoading} = useSelector((store)=>store[userType]);
  const dispatch = useDispatch();
  const { activityId } = useParams();
  const { currentActivity } = useSelector((store) => store[userType]);
  const [formData, setFormData] = useState({
    title: currentActivity?.title,
    description: currentActivity?.description,
    photos: [],
    student: currentActivity?.student,
  });
  
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formDataPhotos = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formDataPhotos.append('photos', files[i]);
    }
  
    const response = await dispatch(uploadToCloud({formDataPhotos}));
    console.log("Response : ",response);
    const fileUrls = response.payload;
    setFormData({ ...formData, photos: [...formData.photos, ...fileUrls] });
  };
  const handleEdit = async (event) => {
    await setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const response = await dispatch(editActivity({ ...formData, activityId }));
    if(response.payload.status === "success")
    {
      setTimeout(() => {
        navigate(-1,{replace : true});  
      }, 1000);
      
    }
  };


  return (
    <section className="pt-16 pb-4">
      <div className="mt-14">
        <h1 className="font-medium text-center text-3xl mb-4">Edit Activity</h1>
        <form
          className="flex flex-col bg-white p-8 rounded-lg shadow-md w-4/5 md:w-3/5 m-auto"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col py-4">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              id="title"
              className="bg-slate-200 px-2 h-10 rounded-md"
              value={formData?.title}
              onChange={handleEdit}
            />
          </div>
          <div className="flex flex-col py-4">
            <label htmlFor="description">Description</label>
            <textarea
              type="text"
              name="description"
              id="description"
              rows="5"
              className="bg-slate-200 p-2 rounded-md"
              value={formData?.description}
              onChange={handleEdit}
            />
          </div>      
          <div className="flex flex-col py-4">
            <label htmlFor="date">Activity Date</label>
            <input type="date" name="date" id="date" onChange={handleEdit} className="bg-slate-200 px-2 h-10 rounded-md"/>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div>
              <label htmlFor="photos">Photos</label>
              <input
                type="file"
                name="photos"
                id="photos"
                multiple
                onChange={handleFileUpload}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 w-24 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <BackDrop open={isLoading}/>
      <ToastContainer/>
    </section>
  );
};

export default EditActivity;
