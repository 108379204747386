import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../config/url";
const user = localStorage.getItem("user");
const token = localStorage.getItem("token");

const initialState = {
  user: user ? JSON.parse(user) : null,
  token: token,
  isLoading: false,
  students: [],
  studentCount: 0,
  currentStudent: {},
  studentActivities: [],
  activityPhotos: [],
  caretakers: [],
  inActiveCaretakers: [],
  currentCaretaker: {},
  caretakerCount: 0,
  donors: [],
  inActiveDonors: [],
  currentDonor: {},
  donorCount: 0,
  uploading: false,
  spendings: [],
  academicRecords: [],
  events: [],
};

export const uploadToCloudAdmin = createAsyncThunk(
  "/upload(admin)",
  async (file, { rejectWithValue }) => {
    console.log(file);
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const adminLogin = createAsyncThunk(
  "admin/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/admin/login`, payload);
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "api/admin/profile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/profile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCount = createAsyncThunk(
  "admin/count",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/utils/count`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudents = createAsyncThunk(
  "admin/students",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudent = createAsyncThunk(
  "admin/student/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/student/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudentActivitiesAdmin = createAsyncThunk(
  "admin/activities/:studentId(get)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/activities/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityAdmin = createAsyncThunk(
  "admin/activity/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/activity/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDonors = createAsyncThunk(
  "admin/donors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/donors`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Get donors Response:", response);
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDonor = createAsyncThunk(
  "admin/donor/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/donor/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCaretakers = createAsyncThunk(
  "admin/caretakers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/caretakers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCaretaker = createAsyncThunk(
  "admin/caretaker/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/caretaker/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "api/admin/addAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/admin/new`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registerDonor = createAsyncThunk(
  "api/admin/donors",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/donors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registerCaretaker = createAsyncThunk(
  "api/admin/caretakers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/caretakers`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registerStudent = createAsyncThunk(
  "api/admin/students",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/students`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addSpending = createAsyncThunk(
  "/students/:studentId/spending(post)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/students/${payload.student}/spending`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addAcademicRecord = createAsyncThunk(
  "/students/:studentId/academicRecord(post)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/students/${payload.student}/academicRecord`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteSpending = createAsyncThunk(
  "spending/:spendingId(delete)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/admin/spending/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteAcademicRecord = createAsyncThunk(
  "academicRecord/:academicRecordId(delete)",

  async (payload, { rejectWithValue }) => {
    console.log(payload);
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/admin/academicRecord/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignCaretaker = createAsyncThunk(
  "student/assignCaretaker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/student/${payload.studentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const assignDonor = createAsyncThunk(
  "student/assignDonor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/donor/${payload.studentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// export const removeDonorForStudent = createAsyncThunk(
//   "student/removeDonorForStudent",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const response = await axios.delete(
//         `${BASE_URL}/api/admin/donor/${payload.studentId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       if (!error?.response) {
//         throw error;
//       }
//       return rejectWithValue(error?.response?.data);
//     }
//   }
// );

export const addGroupActivity = createAsyncThunk(
  "/students/activity(post)(group activity)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/activity/students`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadAdminPhoto = createAsyncThunk(
  "/upload/adminPhoto",
  async (file, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/adminPhoto`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSpendingsAdmin = createAsyncThunk(
  "students/:studentId/spending(Get)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/students/${payload.id}/spending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addActivity = createAsyncThunk(
  `api/admin/students/addActivity`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/students/${payload.student}/activities`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addEvent = createAsyncThunk(
  "/admin/addEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/events`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getEvents = createAsyncThunk(
  "/admin/events(get)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/events`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "/admin/event/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/admin/events/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadStudentPhoto = createAsyncThunk(
  "upload/uploadStudentPhoto(post)",
  async (file, { rejectWithValue }) => {
    console.log(file);
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/studentPhoto`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadStudentDocument = createAsyncThunk(
  "upload/uploadStudentDocument(post)",
  async (file, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/studentDocument`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadCaretakerPhoto = createAsyncThunk(
  "upload/uploadcaretaker photo(post)",
  async (file, { rejectWithValue }) => {
    console.log(file);
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/caretakerPhoto`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadDonorPhoto = createAsyncThunk(
  "upload/uploaddonorphoto(post)",
  async (file, { rejectWithValue }) => {
    console.log(file);
    const formData = new FormData();
    formData.append("photos", file);
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/donorPhoto`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadGroupActivity = createAsyncThunk(
  "upload/groupActivity",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/groupActivity`,
        payload.formDataPhotos,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editStudent = createAsyncThunk(
  "admin/student/:id/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/student/${payload.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAcademicRecordsAdmin = createAsyncThunk(
  "academicRecordsAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/students/${payload.id}/academicRecord`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const makeCaretakerActive = createAsyncThunk(
  "caretaker/:id/makeActive",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/caretaker/${payload.id}/makeActive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const makeCaretakerInactive = createAsyncThunk(
  "caretaker/:id/makeInactive",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/caretaker/${payload.id}/makeInactive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeCaretaker = createAsyncThunk(
  "caretaker/:id/remove",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload.id);
      const response = await axios.delete(
        `${BASE_URL}/api/admin/caretaker/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const makeDonorActive = createAsyncThunk(
  "donor/:id/makeActive",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/donor/${payload.id}/makeActive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unassignDonor = createAsyncThunk(
  "/donor/unassign/:studentId",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/donor/unassign/${payload.studentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const makeDonorInactive = createAsyncThunk(
  "donor/:id/makeInactive",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/admin/donor/${payload.id}/makeInactive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeDonor = createAsyncThunk(
  "donor/:id/remove",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/admin/donor/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "admin/student/:id(delete-student)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/admin/student/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { adminData, token } = payload;
      localStorage.setItem("user", JSON.stringify(adminData));
      localStorage.setItem("token", token);
      state.user = adminData;
      state.token = token;
      toast.success("Login successful!");
    });
    builder.addCase(adminLogin.rejected, (state, payload) => {
      state.isLoading = false;
      toast.error("Invalid credentials");
    });

    builder.addCase(uploadToCloudAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadToCloudAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("File uploaded");
    });

    builder.addCase(uploadToCloudAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(editProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log("Full filled payload", payload);
      const updatedDetails = payload.updatedUser;
      localStorage.setItem("user", JSON.stringify(updatedDetails));
      state.message = payload.message;
      state.user = updatedDetails;
      toast.success("Details Updated!");
    });

    builder.addCase(editProfile.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
      toast.error(payload.message);
    });

    builder.addCase(getCount.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { studentCount, donorCount, caretakerCount } = payload;
      state.studentCount = studentCount;
      state.donorCount = donorCount;
      state.caretakerCount = caretakerCount;
    });

    builder.addCase(getCount.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(registerStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(registerStudent.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      toast.success("New student added!");
    });

    builder.addCase(registerStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(registerCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(registerCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("New caretaker added");
    });

    builder.addCase(registerCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(registerDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(registerDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("New donor added");
    });

    builder.addCase(registerDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(addAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("New admin added");
    });

    builder.addCase(addAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getStudents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { students } = payload;
      state.students = students;
    });

    builder.addCase(getStudents.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentStudent = payload.student;
    });

    builder.addCase(getStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error("oiuwu;lafhufad");
    });

    builder.addCase(getStudentActivitiesAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getStudentActivitiesAdmin.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.studentActivities = payload.data;
      }
    );

    builder.addCase(
      getStudentActivitiesAdmin.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload.message);
      }
    );

    builder.addCase(getActivityAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getActivityAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentActivity = payload.data;
      state.activityPhotos = state.currentActivity.photos;
    });

    builder.addCase(getActivityAdmin.rejected, (state, { payload }) => {
      console.log("rejected state ");
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getDonors.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDonors.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log("Get all donors:", payload);
      const { donors } = payload;
      console.log("donors", donors);
      state.donors = donors;
      state.inActiveDonors = payload.inActiveDonors;
    });

    builder.addCase(getDonors.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentDonor = payload.donor;
      state.students = payload.donor.students;
    });

    builder.addCase(getDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getCaretakers.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCaretakers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { caretakers } = payload;
      state.caretakers = caretakers;
      state.inActiveCaretakers = payload.inActiveCaretakers;
    });

    builder.addCase(getCaretakers.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentCaretaker = payload.caretaker;
      state.students = payload.caretaker.students;
    });

    builder.addCase(getCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(addSpending.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addSpending.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Spending Added!");
    });

    builder.addCase(addSpending.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(addAcademicRecord.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addAcademicRecord.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Academic Record Added!");
    });

    builder.addCase(addAcademicRecord.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(deleteSpending.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteSpending.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.spendings = payload.spendings;
      toast.success("Spending Deleted!");
    });

    builder.addCase(deleteSpending.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(deleteAcademicRecord.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteAcademicRecord.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.academicRecords = payload.academicRecords;
      toast.success("Academic Record Deleted!");
    });

    builder.addCase(deleteAcademicRecord.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(assignCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(assignCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Caretaker assigned!");
    });

    builder.addCase(assignCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(assignDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(assignDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Donor assigned!");
    });

    builder.addCase(assignDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    // builder.addCase(removeDonorForStudent.pending, (state) => {
    //   state.isLoading = true;
    // });

    // builder.addCase(removeDonorForStudent.fulfilled, (state, { payload }) => {
    //   state.isLoading = false;
    //   toast.success("Donor removed!");
    //   window.location.reload();
    // });

    // builder.addCase(removeDonorForStudent.rejected, (state, { payload }) => {
    //   state.isLoading = false;
    //   toast.error("Unable to remove donor!");
    // });

    builder.addCase(addGroupActivity.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addGroupActivity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Activity send to all students");
    });

    builder.addCase(addGroupActivity.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getSpendingsAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSpendingsAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.spendings = payload.data;
    });

    builder.addCase(getSpendingsAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(addEvent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("New Event Added!");
    });

    builder.addCase(addEvent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.events = payload.events;
    });

    builder.addCase(getEvents.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(deleteEvent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteEvent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.events = payload.events;
      toast.success("Event Deleted!");
    });

    builder.addCase(deleteEvent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(editStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(editStudent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log("Full filled payload", payload);
      const updatedDetails = payload.updatedStudent;
      state.message = payload.message;
      state.currentStudent = updatedDetails;
      toast.success("Student Details Updated!");
    });

    builder.addCase(editStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
      toast.error(payload.message);
    });

    builder.addCase(uploadStudentPhoto.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadStudentPhoto.fulfilled, (state, payload) => {
      state.isLoading = false;
      toast.success("Student photo uploaded");
    });

    builder.addCase(uploadStudentPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadStudentDocument.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadStudentDocument.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success("Student document uploaded");
    });

    builder.addCase(uploadStudentDocument.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadCaretakerPhoto.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadCaretakerPhoto.fulfilled, (state, payload) => {
      state.isLoading = false;
      toast.success("Caretaker photo uploaded");
    });

    builder.addCase(uploadCaretakerPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadDonorPhoto.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadDonorPhoto.fulfilled, (state, payload) => {
      state.isLoading = false;
      toast.success("Donor photo uploaded");
    });

    builder.addCase(uploadDonorPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadAdminPhoto.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadAdminPhoto.fulfilled, (state, payload) => {
      state.isLoading = false;
      toast.success("Admin photo uploaded");
    });

    builder.addCase(uploadAdminPhoto.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getAcademicRecordsAdmin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAcademicRecordsAdmin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.academicRecords = payload.academicRecords;
    });

    builder.addCase(getAcademicRecordsAdmin.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(makeCaretakerActive.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(makeCaretakerActive.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.caretakers = payload.activeCaretakers;
      state.inActiveCaretakers = payload.inActiveCaretakers;
      toast.success(payload.message);
    });

    builder.addCase(makeCaretakerActive.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(makeCaretakerInactive.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(makeCaretakerInactive.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.caretakers = payload.activeCaretakers;
      state.inActiveCaretakers = payload.inActiveCaretakers;
      toast.success(payload.message);
    });

    builder.addCase(makeCaretakerInactive.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(removeCaretaker.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(removeCaretaker.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.caretakers = payload.activeCaretakers;
      state.inActiveCaretakers = payload.inActiveCaretakers;
      toast.success(payload.message);
    });

    builder.addCase(removeCaretaker.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(makeDonorActive.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(makeDonorActive.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.donors = payload.activeDonors;
      state.inActiveDonors = payload.inActiveDonors;
      toast.success(payload.message);
    });

    builder.addCase(makeDonorActive.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(makeDonorInactive.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(makeDonorInactive.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.donors = payload.activeDonors;
      state.inActiveDonors = payload.inActiveDonors;
      toast.success(payload.message);
    });

    builder.addCase(makeDonorInactive.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(removeDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(removeDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.donors = payload.activeDonors;
      state.inActiveDonors = payload.inActiveDonors;
      toast.success(payload.message);
    });

    builder.addCase(removeDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(unassignDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(unassignDonor.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.isLoading = false;
      console.log(payload);
      state.currentStudent = payload.student;
      toast.success(payload.message);
    });

    builder.addCase(unassignDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(deleteStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteStudent.fulfilled, (state, { payload }) => {
      
      state.isLoading = false;
      state.students = payload.students;
      toast.success(payload.message);
    });

    builder.addCase(deleteStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });
  },
});

export const { handleAddSpending, cancelAddSpending } = adminSlice.actions;
export default adminSlice.reducer;
