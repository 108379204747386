import React from "react";

const Contribute = () => {
  const imageURL =
    "https://booktalks.s3.ap-south-1.amazonaws.com/children-trip.jpeg";
  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  };
  return (
    <div
      id="join-us"
      className="text-center bg-center bg-cover h-[570px] lg:h-[735px] overflow-x-hidden w-full relative"
      style={{
        backgroundImage: `url(${imageURL})`,
      }}
    >
      <div style={overlayStyle}></div>
      <div className="text-center text-white absolute inset-0 flex py-20 justify-center z-10 max-w-7xl mx-auto">
        <div className="flex-col justify-between space-y-16">
          <div className="flex flex-col justify-start items-center h-1/2 lg:px-36">
            <h1 className="text-xl font-semibold lg:text-5xl lg:font-semibold">
              You can contribute to provide a place for children with special
              needs!
            </h1>
          </div>
          <div className="flex space-x-5 justify-center items-center h-1/2">
            <a
              href="https://wearewithyouct.org/contact"
              target="_blank"
              rel="noreferrer"
              className="rounded-[4px] bg-blueTheme text-sm font-semibold text-white shadow-sm lg:w-[275px] w-[120px] h-[51px] text-center flex justify-center items-center"
            >
              Join as a volunteer
            </a>
            {/* <a
              href="https://wearewithyouct.org/donate"
              target="_blank"
              rel="noreferrer"
              className="rounded-[4px] bg-white text-sm font-semibold text-black shadow-sm lg:w-[115px] lg:h-[51px] w-[120px] h-[40px] text-center flex justify-center items-center"
            >
              Donate
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contribute;
