import React from "react";
import {  useNavigate } from "react-router-dom";

const UnAuthorized = () => {
  const navigate = useNavigate();
  
  
  return (
    <div className="flex flex-col gap-5 h-screen w-screen items-center justify-center">
      <h1 className="text-xl font-bold">
        You are not Allowed to access this resource
      </h1>
      
      <button
        className="bg-blue-500 p-2 rounded-md text-white font-semibold"
        onClick={() => navigate(-1, { replace: true })}
      >
        Go Back
      </button>
    </div>
  );
};

export default UnAuthorized;
