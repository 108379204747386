import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { useState } from "react";
import BackDrop from "../utils/BackDrop";
import { useNavigate, useParams } from "react-router-dom";
import { addSpending } from "../features/admin/adminSlice";

const AddSpending = () => {
  const {id} = useParams();
  const navigate = useNavigate();
	const {isLoading} = useSelector((store)=>store["admin"]);
	const dispatch = useDispatch();
  const [form, setForm] = useState({
    title: "",
    amount: "",
    date: "",
		student : id
  });
	const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
	const handleSubmit = async(e) => {
		e.preventDefault();
    const response = await dispatch(addSpending(form));
    if(response.payload.status === "success")
      navigate(-1,{replace : true});
	}
  return (
    <div>
      <div className="flex items-center justify-center flex-col gap-y-5 bg-gray-100  p-10 mt-14">
        <div className="w-auto">
          <form className="grid sm:grid-cols-1 gap-x-10 gap-y-4">
            <h2 className="font-bold text-black text-xl text-center">
							Add Spending
            </h2>
            <div className="flex  justify-center gap-y-2 flex-col">
              <label className="font-semibold text-black" htmlFor="query title">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={form.title}
                onChange={handleFormChange}
                className="text-black rounded-md p-3"
              />
            </div>

            <div className="flex  justify-center gap-y-2 flex-col">
              <label
                className="font-semibold text-black"
                htmlFor="query description"
              >
                Amount
              </label>
              <input
                type="text"
                name="amount"
                value={form.amount}
                onChange={handleFormChange}
                className="text-black rounded-md p-3"
              />
            </div>
						<div className="flex  justify-center gap-y-2 flex-col">
              <label
                className="font-semibold text-black"
                htmlFor="query description"
              >
                Date
              </label>
              <input
                type="date"
                name="date"
                value={form.date}
                onChange={handleFormChange}
                className="text-black rounded-md p-3"
              />
            </div>
            
            <div className="flex justify-center gap-x-5 px-8">
              <input
                type="button"
                name="cancel"
                value="Cancel"
                onClick={() =>navigate(-1)}
                className="bg-red-500 text-white text-md font-bold w-24 p-3 rounded-md mt-3 hover:bg-red-700 cursor-pointer"
              />
              <input
                type="submit"
                name="submit"
                value="Save"
                onClick={handleSubmit}
                className="bg-blue-600 text-white text-md font-bold w-24 p-3 rounded-md mt-3 hover:bg-blue-700 cursor-pointer"
              />
            </div>
          </form>
        </div>
        <BackDrop open={isLoading}></BackDrop>
      </div>
    </div>
  );
};

export default AddSpending;
