import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addActivity as careTakerAddActivity } from "../../features/caretaker/caretakerSlice";
import { addActivity as adminAddActivity } from "../../features/admin/adminSlice";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadToCloud } from "../../features/common/commonSlice";
const AddActivity = ({ userType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    photos: [],
    date: "",
    student: id,
  });
  const { uploading } = useSelector((store) => store["common"]);
  const { isLoading } = useSelector((store) => store["caretaker"]);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formDataPhotos = new FormData();

    for (let i = 0; i < files.length; i++) {
      formDataPhotos.append("photos", files[i]);
    }

    const response = await dispatch(uploadToCloud({ formDataPhotos }));
    console.log("Response : ", response);
    const fileUrls = response.payload;
    setFormData({ ...formData, photos: [...fileUrls] });
  };

  const handleInputChange = async (event) => {
    await setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Entered Data : ", formData);
    var response;
    if (userType === "admin") {
      response = await dispatch(adminAddActivity(formData));
    } else {
      response = await dispatch(careTakerAddActivity(formData));
    }
    if (response.payload.status === "success") {
      navigate(`/${userType}/students/${id}`);
    }
  };

  return (
    <div className="pt-16 pb-4 min-w-screen ">
      <div className="mt-10 font-medium p-7">
        <h1 className="font-medium text-center text-3xl mb-4">Add Activity</h1>
        <form
          className="flex flex-col mx-auto bg-white p-7 rounded-lg shadow-md w-full md:w-3/5"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col py-4">
            <label className="block font-medium" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className="bg-slate-200 px-2 h-10 rounded-md"
              value={formData.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col py-4">
            <label className="block font-medium" htmlFor="description">
              Description
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              rows="5"
              className="bg-slate-200 p-2 rounded-md"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col py-4">
            <label className="block font-medium" htmlFor="date">
              Activity Date
            </label>
            <input
              type="date"
              name="date"
              id="date"
              onChange={handleInputChange}
              className="bg-slate-200 px-2 h-10 rounded-md"
            />
          </div>
          <div className="flex flex-col md:flex-row md:justify-between mt-4">
            <div>
              <label
                className="block font-medium  md:w-3/4 lg:w-full"
                htmlFor="photos"
              >
                Photos
              </label>
              <input
                type="file"
                name="photos"
                id="photos"
                className="md:w-3/4 lg:w-full"
                multiple
                onChange={handleFileUpload}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 w-20 hover:bg-blue-700 text-white font-bold p-2 rounded mt-4"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <BackDrop open={isLoading || uploading} />
      <ToastContainer />
    </div>
  );
};

export default AddActivity;
