import React, { useEffect } from "react";
import { StudentsContainer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getStudents } from "../../features/caretaker/caretakerSlice";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Welcome = () => {
  const { isLoading } = useSelector((store) => store["caretaker"]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudents());
  }, [dispatch]);
  return (
    <div className="pt-16 mt-10 pb-4">
      <h1 className="text-2xl font-semibold text-center">My Students</h1>
      <StudentsContainer userType="caretaker" />
      <BackDrop open={isLoading} />
      <ToastContainer />
    </div>
  );
};

export default Welcome;
