import { Sidebar, StudentsContainer } from "../../components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStudents } from "../../features/admin/adminSlice";
import { useNavigate } from "react-router-dom";

const AllStudents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudents());
  }, [dispatch]);
  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
        <div className="flex justify-between items-center mx-auto px-7 md:px-16 xl:px-28 mt-12">
          {/* Students Heading */}
          <h2 className="font-semibold text-2xl text-black">Our Students</h2>
          <input
            type="button"
            name="add Student"
            value="Add Student"
            className="text-white bg-blue-500 font-bold cursor-pointer py-2 px-4 rounded-md"
            onClick={() => navigate("/admin/createstudent")}
          />
        </div>
        <StudentsContainer userType="admin" />
      </section>
    </section>
  );
};

export default AllStudents;
