import React from "react";
import VaaradhiNavbar from "./VaaradhiNavbar";
import { VaaradhiFooter } from "./VaaradhiFooter";
import Header from "./Header";
import AboutUs from "./AboutUs";
import WhatWeDo from "./WhatWeDo";
import Contribute from "./Contribute";
import Gallery from "./Gallery";

function Landing() {
  return (
    <div>
      <VaaradhiNavbar />
      <Header />
      <AboutUs />
      <WhatWeDo />
      <Gallery />
      <Contribute />
      <VaaradhiFooter />
    </div>
  );
}

export default Landing;
