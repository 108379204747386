import React from "react";

const Header = () => {
  const imageURL = "https://booktalks.s3.ap-south-1.amazonaws.com/hero.jpeg";
  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  };
  return (
    <div
      id="home"
      className="text-center bg-center bg-cover h-[570px] lg:h-[735px] overflow-x-hidden w-full relative"
      style={{
        backgroundImage: `url(${imageURL})`,
      }}
    >
      <div style={overlayStyle}></div>
      <div className="text-center text-white absolute inset-0 flex py-20 justify-center z-10">
        <div className="flex-col justify-between">
          <div className="flex flex-col justify-start items-center h-1/2">
            <h1 className="text-3xl font-extrabold lg:text-9xl lg:font-black">
              VAARADHI
            </h1>
          </div>
          <div className="flex flex-col justify-end items-center h-1/2">
            <p className="px-3 py-2 text-base lg:text-lg font-semibold text-white">
              A project by{" "}
              <a
                href="https://wearewithyouct.org"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                We Are With You Charitable Trust
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
