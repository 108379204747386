import { Sidebar } from "../../components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCaretakers } from "../../features/admin/adminSlice";
import CaretakersContainer from "../../components/CaretakersContainer";
import { useNavigate } from "react-router-dom";
const AllCaretakers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCaretakers());
  }, [dispatch]);
  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
        <div className="flex justify-center items-center mx-auto  md:flex-row sm:px-10 sm:justify-between md:px-16 xl:px-28 mt-12">
          {/* Students Heading */}
          <h2 className="font-semibold  text-2xl text-black">Our Caretakers</h2>
          <input
            type="button"
            name="Add Caretaker"
            value="Add Caretaker"
            className="text-white bg-blue-500 font-bold cursor-pointer ml-3 max-w-fit py-2 px-3 rounded-md"
            onClick={() => navigate("/admin/newcaretaker")}
          />
        </div>
        <CaretakersContainer userType="admin" />
      </section>
    </section>
  );
};

export default AllCaretakers;
