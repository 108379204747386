import React from "react";
import { Sidebar } from "../../components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsCloudUpload } from "react-icons/bs";
import { uploadToCloud } from "../../features/common/commonSlice";
import { addGroupActivity } from "../../features/admin/adminSlice";
const GroupActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    photos: [],
    date: "",
  });
  const { uploading } = useSelector((store) => store["common"]);
  const { isLoading } = useSelector((store) => store["admin"]);

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formDataPhotos = new FormData();

    for (let i = 0; i < files.length; i++) {
      formDataPhotos.append("photos", files[i]);
    }

    const response = await dispatch(uploadToCloud({ formDataPhotos }));
    console.log("Response : ", response);
    const fileUrls = response.payload;
    setFormData({ ...formData, photos: [...fileUrls] });
  };

  const handleInputChange = async (event) => {
    await setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Entered Data : ", formData);
    const response = await dispatch(addGroupActivity(formData));
    console.log(response);
    if (response.payload.status === "success") {
      setTimeout(() => {
        navigate(`/admin`, { replace: true });
      }, 2000);
    }
  };
  return (
    <section>
      <Sidebar />
      <section className="bg-slate-100 pt-16 pb-4 lg:pl-64 px-4">
        <h2 className="mt-10 text-center font-semibold text-xl text-gray-800">
          Add Group Activity
        </h2>
        <div className="mt-5">
          <form
            className="flex flex-col space-y-4 md:space-y-0  bg-white p-4 md:p-8 rounded-lg shadow-md w-full md:w-3/5 m-auto"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:py-4">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                id="title"
                className="bg-slate-200 px-2 h-10 rounded-md"
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col md:py-4">
              <label htmlFor="description">Description</label>
              <textarea
                type="text"
                name="description"
                id="description"
                rows="3"
                className="bg-slate-200 p-2 rounded-md"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col md:py-4">
              <label htmlFor="title">Activity Date</label>
              <input
                type="date"
                name="date"
                id="date"
                className="bg-slate-200 px-2 h-10 rounded-md"
                value={formData.date}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between mt-5">
              <div className="flex items-center justify-start w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col w-full md:w-1/3 items-center justify-center border-2 rounded-lg cursor-pointer hover:bg-blue-800 bg-blue-700"
                >
                  <div className="flex flex-col items-center justify-center py-3">
                    <BsCloudUpload className="w-6 h-6 mb-3 text-white" />
                    <p className="mb-2 text-sm text-white dark:text-white">
                      <span className="font-semibold">Click to upload</span>
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                    accept=".jpg,.png,.jpeg"
                    multiple
                  />
                </label>
              </div>
              <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-blue-500 w-full text-center mt-4 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded h-1/2"
              >
                Submit
              </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <BackDrop open={isLoading || uploading} />
      <ToastContainer />
    </section>
  );
};

export default GroupActivity;
