import React from "react";
import WhatWeDoImg from "../../assets/whatwedo.jpeg";

const WhatWeDo = () => {
  return (
    <div id="what-we-do" className="w-full bg-blueTheme text-white">
      <div className="max-w-7xl px-4 py-6 mx-auto flex flex-col items-center lg:gap-x-20 lg:gap-y-4 lg:py-16 md:flex-row">
        <div className="space-y-4 lg:space-y-6 lg:w-1/2">
          <p className="text-lg font-bold lg:text-base text-black">
            WHAT WE DO
          </p>
          <p className="text-xl font-bold lg:text-3xl text-white text-justify">
            Some services we provide for our children
          </p>
          <ul className="list-disc pl-6 text-sm lg:text-base text-white md:text-lg text-justify">
            <li>Admitting children in schools and paying the fee if needed.</li>
            <li>Buying school bags and related stationery.</li>
            <li>Buying clothes.</li>
            <li>
              Assigning a personal caretaker for each child who provides
              emotional support to the child.
            </li>
          </ul>

          <p className="text-base font-semibold lg:text-lg text-white text-justify">
            At present, there are 72 children taken care of under this project.
          </p>
        </div>
        <div className="lg:mt-0 mt-8 lg:w-1/2">
          <img src={WhatWeDoImg} alt="Getting Started" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
