import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Activities from "./Activities";
import BackDrop from "../utils/BackDrop";
import { getStudent as getDonorStudent } from "../features/donor/donorSlice";
import { getStudent as getCareTakerStudent } from "../features/caretaker/caretakerSlice";
import {
  deleteStudent,
  getStudent as getAdminStudent,
  unassignDonor,
} from "../features/admin/adminSlice";
import { BiLinkExternal } from "react-icons/bi";
import Spendings from "./Spendings";
import AcademicRecord from "./AcademicRecord";
import { MdDelete } from "react-icons/md";

const Student = ({ userType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentStudent } = useSelector((store) => store[userType]);
  const { isLoading } = useSelector((store) => store[userType]);
  useEffect(() => {
    if (userType === "donor") {
      dispatch(getDonorStudent({ id }));
    } else if (userType === "caretaker") {
      dispatch(getCareTakerStudent({ id }));
    } else if (userType === "admin") {
      dispatch(getAdminStudent({ id }));
    }
  }, [dispatch, id, userType]);

  const dob = currentStudent.dateOfBirth;
  const date = new Date(dob);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Note: January is 0 in JavaScript, so we need to add 1
  const year = date.getFullYear();
  const dateOfBirth = day + "-" + month + "-" + year;

  return (
    <div className="m-2 py-28 sm:m-10">
      {/* Student Details  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-y-6">
        {/* Student Image div */}
        <div className="flex items-center justify-center flex-col gap-y-4 md:gap-y-6">
          <img
            src={currentStudent.image}
            alt="student"
            className="rounded-full h-52 w-52"
          />
          {userType === "admin" && (
            <div className="flex flex-col gap-5">
              <input
                type="button"
                name="edit student"
                value="Edit Student"
                onClick={() => navigate("edit")}
                className="text-white bg-blue-500 font-bold cursor-pointer py-2 px-4 rounded-md"
              />
              <button
                className="text-white bg-red-500 font-bold cursor-pointer py-2 px-4 rounded-md"
                onClick={() => {
                  dispatch(deleteStudent({ id: id }));
                  navigate(`/admin/students`);
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
        {/* Student details Div */}
        <div className="p-4">
          <h3 className="font-bold text-center text-xl md:text-2xl mb-4 md:mb-6">
            Student Details
          </h3>
          <div className="flex flex-col justify-center gap-y-4  md:ml-1">
            <div className="flex  md:justify-start">
              <label
                htmlFor="studentName"
                className="font-semibold flex justify-start text-xl"
              >
                Name :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.name}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="dateOfBirth"
                className="font-semibold flex justify-start text-xl"
              >
                DOB :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {dateOfBirth}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="category"
                className="font-semibold flex justify-start text-xl"
              >
                Category :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.category}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="gender"
                className="font-semibold flex justify-start text-xl"
              >
                Gender :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.gender}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="institutionName"
                className="font-semibold flex justify-start text-xl"
              >
                Institution :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.education?.institution}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="class"
                className="font-semibold flex justify-start text-xl"
              >
                Class :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.education?.class}
              </p>
            </div>
            <div className="flex  md:justify-start">
              <label
                htmlFor="previousYearPercentage"
                className="font-semibold flex justify-start text-xl"
              >
                Previous Percentage :&nbsp;
              </label>
              <p className="text-lg ml-1 flex justify-end font-medium">
                {currentStudent.previousYearPercentage}
              </p>
            </div>
          </div>
        </div>
        {/* Student Family Details */}
        <div className="p-4 flex flex-col justify-start md:justify-start  md:items-center">
          <h3 className="font-bold text-center text-xl md:text-2xl mb-4 md:mb-6">
            Family Details
          </h3>
          {/* if student is orphan */}
          {currentStudent.category === "orphan" && (
            <div className="flex justify-start flex-row">
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-start">
                  <label
                    htmlFor="guardianName"
                    className="font-semibold text-xl"
                  >
                    Guardian:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.guardian?.name}
                  </p>
                </div>
                <div className="flex justify-start">
                  <label htmlFor="occupation" className="font-semibold text-xl">
                    Occupation:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.guardian?.occupation}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* if student is poor */}
          {currentStudent.category === "poor student" && (
            <div className="flex justify-start flex-row">
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-start">
                  <label htmlFor="motherName" className="font-semibold text-xl">
                    Mother:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.mother?.name}
                  </p>
                </div>
                <div className="flex justify-start">
                  <label
                    htmlFor="motherOccupation"
                    className="font-semibold text-xl"
                  >
                    Occupation:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.mother?.occupation}
                  </p>
                </div>
                <div className="flex justify-start">
                  <label htmlFor="fatherName" className="font-semibold text-xl">
                    Father:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.father?.name}
                  </p>
                </div>
                <div className="flex justify-start">
                  <label
                    htmlFor="fatherOccupation"
                    className="font-semibold text-xl"
                  >
                    Occupation:&nbsp;
                  </label>
                  <p className="text-lg font-medium">
                    {currentStudent.father?.occupation}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* if student is semiorphan */}
          {currentStudent.category === "semi orphan" &&
            currentStudent?.mother?.name !== "" && (
              <div className="flex justify-start flex-row">
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-start">
                    <label
                      htmlFor="motherName"
                      className="font-semibold text-xl"
                    >
                      Mother:&nbsp;
                    </label>
                    <p className="text-lg font-medium">
                      {currentStudent.mother?.name}
                    </p>
                  </div>
                  <div className="flex justify-start">
                    <label
                      htmlFor="motherOccupation"
                      className="font-semibold text-xl"
                    >
                      Occupation:&nbsp;
                    </label>
                    <p className="text-lg font-medium">
                      {currentStudent.mother?.occupation}
                    </p>
                  </div>
                </div>
              </div>
            )}
          {currentStudent.category === "semi orphan" &&
            currentStudent?.father?.name !== "" && (
              <div className="flex justify-around flex-row">
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-start">
                    <label
                      htmlFor="fatherName"
                      className="font-semibold text-xl"
                    >
                      Father:&nbsp;
                    </label>
                    <p className="text-lg font-medium">
                      {currentStudent.father?.name}
                    </p>
                  </div>
                  <div className="flex justify-start">
                    <label
                      htmlFor="fatherOccupation"
                      className="font-semibold text-xl"
                    >
                      Occupation:&nbsp;
                    </label>
                    <p className="text-lg font-medium">
                      {currentStudent.father?.occupation}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-evenly flex-col md:flex-row">
          {/* Student Documents */}
          <div className="flex flex-col mb-6 px-4 justify-center items-center">
            <h3 className="font-bold text-center text-2xl mb-3 md:mb-6">
              Documents
            </h3>
            <div className="flex items-start flex-col gap-y-1">
              {currentStudent?.documents &&
                currentStudent?.documents.map((document, index) => {
                  return (
                    document?.url && (
                      <a
                        href={document?.url}
                        key={index}
                        rel="noreferrer"
                        className=" cursor-pointer font-semibold inline-block"
                        target="_blank"
                      >
                        {document?.name}
                        <span className="ml-2 inline-block">
                          <BiLinkExternal />
                        </span>
                      </a>
                    )
                  );
                })}
              {currentStudent?.previousYearMarksDocument && (
                <a
                  href={currentStudent?.previousYearMarksDocument}
                  rel="noreferrer"
                  className=" cursor-pointer font-semibold inline-block"
                  target="_blank"
                >
                  Academic Report
                  <span className="ml-2 inline-block">
                    <BiLinkExternal />
                  </span>
                </a>
              )}
            </div>
          </div>
          {/* Caretaker details */}
          {userType !== "caretaker" && (
            <div className="mb-6">
              <div className="flex justify-around items-center mb-3 px-4 md:mb-6 gap-x-6">
                <h3 className="font-bold text-center text-xl md:text-2xl">
                  Caretaker Details
                </h3>
                {userType === "admin" && (
                  <button
                    className="bg-blue-500 text-white py-1 px-3  ml-5 rounded-md"
                    onClick={() =>
                      navigate(`/admin/students/${id}/mapcaretaker`)
                    }
                  >
                    Edit
                  </button>
                )}
              </div>
              {!currentStudent?.careTaker ? (
                <div className="flex justify-center text-lg">
                  Caretaker not Assigned
                </div>
              ) : (
                <div className="mb-6 px-4 items-start">
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-col gap-y-4">
                      <div className="flex justify-start">
                        <label
                          htmlFor="adminName"
                          className="font-semibold text-xl"
                        >
                          Name:&nbsp;
                        </label>
                        <p className="text-lg font-medium">
                          {currentStudent?.careTaker?.name}
                        </p>
                      </div>
                      <div className="flex justify-start">
                        <label
                          htmlFor="adminContact"
                          className="font-semibold text-xl"
                        >
                          Contact:&nbsp;
                        </label>
                        <p className="text-lg font-medium">
                          {currentStudent?.careTaker?.mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Admin details */}
          <div className="mb-6 px-4 items-start">
            <h3 className="font-bold text-center text-2xl mb-3 md:mb-6">
              Admin Details
            </h3>
            <div className="flex flex-row justify-center">
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-start">
                  <label htmlFor="adminName" className="font-semibold text-xl">
                    Name:&nbsp;
                  </label>
                  <p className="text-lg font-medium">Ayyappa Siginam</p>
                </div>
                <div className="flex justify-start">
                  <label
                    htmlFor="adminContact"
                    className="font-semibold text-xl"
                  >
                    Contact:&nbsp;
                  </label>
                  <p className="text-lg font-medium">9110743341</p>
                </div>
              </div>
            </div>
          </div>
          {/* Donor Details */}
        </div>
        {userType === "admin" && (
          <div className=" mt-7 w-full flex  flex-col items-center justify-start text-center">
            <div className="flex justify-around items-center m-6 gap-x-6">
              <h3 className="font-bold text-center text-2xl">Donor Details</h3>
              {userType === "admin" && (
                <div>
                  <button
                    className="bg-blue-500 text-white py-1 px-3 rounded-md"
                    onClick={() => navigate(`/admin/students/${id}/mapdonor`)}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
            {currentStudent?.donors?.length === 0 && (
              <div className="text-xl md:text-2xl font-medium flex items-center justify-center">
                No Donors
              </div>
            )}
            {currentStudent?.donors?.length !== 0 && (
              <div className="lg:w-1/2 w-full mx-auto overflow-auto">
                <table className="table-auto w-full text-left whitespace-no-wrap">
                  <tr className="">
                    <th className="px-4 py-3 text-xl tracking-wider text-gray-900 font-bold bg-gray-100">
                      S.no
                    </th>
                    <th className="px-4 py-3 text-xl tracking-wider text-gray-900 font-bold bg-gray-100">
                      Donor Name
                    </th>
                    <th className="px-4 py-3 text-xl tracking-wider text-gray-900 font-bold bg-gray-100">
                      Contact
                    </th>
                  </tr>
                  {currentStudent?.donors &&
                    currentStudent?.donors?.map((donor, index) => {
                      return (
                        <tr key={index}>
                          <td className="border-t-2 text-lg border-gray-200 px-4 py-3">
                            {index + 1}
                          </td>
                          <td className="border-t-2 text-lg border-gray-200 px-4 py-3">
                            {donor?.name}
                          </td>
                          <td className="border-t-2 text-lg border-gray-200 px-4 py-3">
                            {donor?.mobile}
                          </td>

                          <td
                            className="border-t-2 border-gray-200 px-4 py-3 text-3xl cursor-pointer hover:text-red-600"
                            onClick={() =>
                              dispatch(
                                unassignDonor({
                                  donorId: donor._id,
                                  studentId: id,
                                })
                              )
                            }
                          >
                            <MdDelete />
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="m-3 sm:m-10">
        {/* Activity details  */}
        <Activities userType={userType} />
      </div>
      {/* Spendings on student */}
      <div className="m-3 sm:m-10">
        <Spendings userType={userType} />
      </div>
      <div className="m-3 sm:m-10">
        <AcademicRecord userType={userType} />
      </div>
      <BackDrop open={isLoading}></BackDrop>
    </div>
  );
};

export default Student;
