import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { addAdmin, uploadAdminPhoto } from "../../features/admin/adminSlice";
import BackDrop from "../../utils/BackDrop";
import {toast} from "react-hot-toast"
import { MdAddPhotoAlternate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../components";
const NewAdmin = () => {
  const navigate = useNavigate();
  const { isLoading } = useSelector((store) => store["admin"]);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    image: "",
    email: "",
    password: "",
    mobile: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (confirmPassword !== form.password) {
      toast.error("Password doesnot match")
    } else {
      console.log("Submitted Data: ", form);
      const response = await dispatch(addAdmin(form));
      if (response.payload.status === "success") {
        setTimeout(() => {
          navigate(-1, { reset: true });
        }, 1000);
      }
    }
  };

  const handleFileUpload = async (event) => {
    console.log("name: ", event.target.name);
    const file = event.target.files[0];
    const response = await dispatch(uploadAdminPhoto(file));
    setForm({ ...form, [event.target.name]: response.payload.url });
  };

  const handleFormChange = async (e) => {
    console.log(e.target.name + "  " + e.target.value);
    await setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
      <form onSubmit={handleSubmit}>
        
        <h3 className="font-bold text-2xl text-center mt-10">Admin Details</h3>
        <div className="grid grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-2 gap-3 h-full">
          <div className="flex justify-center items-center">
            {form.image === "" ? (
              <div
                name="image"
                className="rounded-full relative bg-blue-500 h-52 w-52 cursor-pointer md:w-56 md:h-56"
                onClick={handleClick}
              >
                <MdAddPhotoAlternate
                  size={55}
                  className="absolute text-white top-20 left-20"
                />
              </div>
            ) : (
              <img
                src={form.image}
                alt="Upload file"
                name="image"
                onClick={handleClick}
                className="rounded-full h-52 w-52 cursor-pointer"
              />
            )}
            <input
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
            />
          </div>
          <div className=" px-7 flex flex-col justify-center">
            <label htmlFor="name" className="block text-lg font-medium mt-4">
              Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              onChange={handleFormChange}
              value={form.name}
              className="border-gray-400 border-2 rounded-md p-2 md:w-3/4"
            />

            <label htmlFor="email" className="block text-lg font-medium mt-4">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={form.email}
              className="border-gray-400 border-2 rounded-md p-2  md:w-3/4"
              onChange={handleFormChange}
            />

            <label
              htmlFor="password"
              className="block text-lg font-medium mt-4"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              value={form.password}
              className="border-gray-400 border-2 rounded-md p-2  md:w-3/4"
              onChange={handleFormChange}
            />

            <label
              htmlFor="confirmPassword"
              className="block text-lg font-medium mt-4"
            >
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              className="border-gray-400 border-2 rounded-md p-2  md:w-3/4"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <label htmlFor="mobile" className="block text-lg font-medium mt-4">
              Mobile
            </label>
            <input
              type="text"
              name="mobile"
              value={form.mobile}
              className="border-gray-400 border-2 rounded-md p-2  md:w-3/4"
              onChange={handleFormChange}
            />
            <input
              type="submit"
              name="submit"
              value="Add Admin"
              className="block p-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-700 cursor-pointer mt-5 md:w-3/4 "
            />
          </div>
        </div>
      </form>
      <BackDrop open={isLoading} />
    </section>
    
    </section>
  );
};

export default NewAdmin; 
