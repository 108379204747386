import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BackDrop from "../utils/BackDrop";

const DonorsContainer = ({ userType }) => {
  const { donors, isLoading } = useSelector((store) => store[userType]);
  console.log(donors);
  const { inActiveDonors } = useSelector((store) => store[userType]);
  console.log(inActiveDonors);
  return (
    <>
      <div className="grid grid-cols-1 my-8 m-auto gap-y-10 md:gap-y-20 md:grid-cols-2 xxl:grid-cols-3 md:px-16">
        {donors.map((donor) => {
          return (
            <div
              key={donor.id}
              className="flex flex-col bg-white m-auto items-center w-64 sm:w-72 xl:w-80 rounded-lg p-7 px-8 shadow-lg justify-start hover:shadow-xl transition duration-300 ease-in-out h-full"
            >
              <div>
                <img
                  src={donor.image || "https://picsum.photos/200"}
                  alt="donor-img"
                  className="md:w-52 md:h-52 w-40 h-40 object-cover rounded-full"
                />
              </div>

              <div className="text-left mt-4 md:mt-6 h-full w-full">
                <h1 className="md:text-lg text-base  font-semibold leading-tight text-gray-800">
                  <label
                    htmlFor="donor-name"
                    className="font-bold text-black-600"
                  >
                    Name:{" "}
                  </label>
                  <span id="donor-name">{donor.name}</span>
                </h1>
                <p className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                  <label
                    htmlFor="donor-gender"
                    className="font-bold text-grey-600"
                  >
                    Gender:{" "}
                  </label>
                  <span id="donor-gender">{donor.gender}</span>
                </p>
                <p className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                  <label
                    htmlFor="donor-mobile"
                    className="font-bold text-grey-600"
                  >
                    Mobile No:{" "}
                  </label>
                  <span id="donor-mobile">{donor.mobile}</span>
                </p>
              </div>

              <div className="self-start">
                <Link
                  to={`/${userType}/donors/${donor.id}`}
                  className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600 focus:outline-none shadow-md"
                >
                  More details
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      {inActiveDonors?.length !== 0 && (
        <div>
          <h2 className="font-semibold  text-2xl text-black px-2  md:px-16 xl:px-28 mt-12">
            Inactive Donors
          </h2>

          <div className="grid grid-cols-1 my-8 m-auto gap-y-10 md:gap-y-20 md:grid-cols-2 xxl:grid-cols-3 md:px-16 xl:px-28">
            {inActiveDonors &&
              inActiveDonors.map((donor) => {
                return (
                  <div
                    key={donor.id}
                    className="flex flex-col bg-white m-auto items-center w-64 sm:w-72 xl:w-80 rounded-lg p-7 px-8 shadow-lg  hover:shadow-xl transition duration-300 ease-in-out h-full"
                  >
                    <div>
                      <img
                        src={donor.image || "https://picsum.photos/200"}
                        alt="caretaker-img"
                        className="md:w-48 md:h-48 w-40 h-40 object-cover rounded-full"
                      />
                    </div>

                    <div className="text-left mt-4 md:mt-6 h-full w-full">
                      <div className="flex md:text-lg text-base  font-semibold leading-tight text-gray-800">
                        <label
                          htmlFor="caretaker-name"
                          className="font-bold text-black-600"
                        >
                          Name:&nbsp;
                        </label>
                        <span id="caretaker-name">{donor.name}</span>
                      </div>
                      <div className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                        <label
                          htmlFor="donor-gender"
                          className="font-bold text-grey-600"
                        >
                          Gender:{" "}
                        </label>
                        <span id="donor-gender">{donor.gender}</span>
                      </div>
                      <div className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                        <label
                          htmlFor="caretaker-mobile"
                          className="font-bold text-grey-600"
                        >
                          Mobile No:{" "}
                        </label>
                        <span id="caretaker-mobile">{donor.mobile}</span>
                      </div>
                    </div>
                    <div className="self-start">
                      <Link
                        to={`/${userType}/donors/${donor.id}`}
                        className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600 focus:outline-none shadow-md"
                      >
                        More details
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <BackDrop open={isLoading} />
    </>
  );
};

export default DonorsContainer;
