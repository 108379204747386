import React, { useState, useEffect } from "react";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudent,
  uploadStudentPhoto,
} from "../../features/admin/adminSlice";
import { editStudent } from "../../features/admin/adminSlice";
import { useNavigate, useParams } from "react-router-dom";
import { MdAddPhotoAlternate } from "react-icons/md";

const EditStudent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentStudent } = useSelector((store) => store.admin);
  const fileInputRef = useRef(null);
  const { isLoading } = useSelector((store) => store["admin"]);

  useEffect(() => {
    dispatch(getStudent({ id }));
  }, [id, dispatch]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    console.log("name : ", event.target.name);
    const file = event.target.files[0];
    const response = await dispatch(uploadStudentPhoto(file));
    console.log(response);
    setForm({ ...form, [event.target.name]: response.payload.url});
    if (event.target.name === "academicReport") {
      setForm({ ...form, previousYearMarksDocument: response.payload[0] });
    }
  };

  const [form, setForm] = useState({
    id: currentStudent?._id,
    name: currentStudent.name,
    image: currentStudent.image,
    dateOfBirth: currentStudent.dateOfBirth,
    institutionName: currentStudent.education?.institution,
    class: currentStudent.education?.class,
    address: currentStudent.address,
    motherName: currentStudent.mother?.name,
    motherOccupation: currentStudent.mother?.occupation,
    motherContact: currentStudent.mother?.contact,
    fatherName: currentStudent.father?.name,
    fatherOccupation: currentStudent.father?.occupation,
    fatherContact: currentStudent.father?.contact,
    guardianName: currentStudent.guardian?.name,
    guardianOccupation: currentStudent.guardian?.occupation,
    guardianContact: currentStudent.guardian?.contact,
    aadhaarNumber: currentStudent.aadhaar,
    aadhaarCard: currentStudent.documents?.find(
      (doc) => doc.name === "aadhaarCard"
    ).url,
    institutionId: currentStudent.documents?.find(
      (doc) => doc.name === "institutionId"
    ).url,
    incomeDocument: currentStudent.documents?.find(
      (doc) => doc.name === "incomeDocument"
    ).url,
    previousYearPercentage: currentStudent.previousYearPercentage,
    previousYearMarksDocument: currentStudent.previousYearMarksDocument,
  });
  console.log(form);

  const handleFormChange = async (e) => {
    console.log(e.target.name + "  " + e.target.value);
    await setForm({ ...form, [e.target.name]: e.target.value });
  };

  const prepareFormData = (form) => {
    const data = {
      id: currentStudent?._id,
      name: form.name,
      image: form.image,
      aadhaar: form.aadhaarNumber,
      dateOfBirth: form.dateOfBirth,
      mother: {
        name: form.motherName,
        occupation: form.motherOccupation,
        contact: form.motherContact,
      },
      father: {
        name: form.fatherName,
        occupation: form.fatherOccupation,
        contact: form.fatherContact,
      },
      guardian: {
        name: form.guardianName,
        occupation: form.guardianOccupation,
        contact: form.guardianContact,
      },
      previousYearMarksDocument: form.previousYearMarksDocument,
      documents: [
        {
          name: "aadhaarCard",
          url: form.aadhaarCard,
        },
        {
          name: "institutionId",
          url: form.institutionId,
        },
        {
          name: "incomeDocument",
          url: form.incomeDocument,
        },
      ],
      education: {
        institution: form.institutionName,
        class: form.class,
      },
      previousYearPercentage: form.previousYearPercentage,
      address : form.address
    };
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = prepareFormData(form);
    console.log("Form Data", form);
    console.log("Prepared Data : ", data);
    const response = await dispatch(editStudent(data));
    if (response.payload.status === "success") {
      setTimeout(() => {
        navigate("/admin/students", { replace: true });
      }, 1000);
    }
  };

  return (
    <div className="py-24">
      {/* First half */}
      <form onSubmit={handleSubmit}>
        <h3 className="font-bold text-2xl text-center">Edit Student Details</h3>
        <div className="grid grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {/*Image div  */}
          <div className="flex justify-center items-center">
            {form.image === "" ? (
              <div
                name="image"
                className="rounded-full relative bg-blue-500 h-52 w-52 cursor-pointer"
                onClick={handleClick}
              >
                <MdAddPhotoAlternate
                  size={55}
                  className="absolute text-white top-20 left-20"
                />
              </div>
            ) : (
              <img
                src={form.image}
                alt="Upload file"
                name="image"
                onClick={handleClick}
                className="rounded-full h-52 w-52 cursor-pointer"
              />
            )}
            <input
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
            />
          </div>

          {/* Personal Details */}
          <div className="">
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                onChange={handleFormChange}
                value={form.name}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="dob" className="block text-lg font-medium mt-4">
                Date of Birth
              </label>
              <input
                id="dob"
                type="date"
                name="dateOfBirth"
                value={form.dateOfBirth}
                onChange={handleFormChange}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="address"
                className="block text-lg font-medium mt-4"
              >
                Address
              </label>
              <textarea
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3 bg-white"
                name="address"
                rows="4"
                value={form.address}
                onChange={handleFormChange}
              />
            </div>
          </div>

          {/* educational Details */}
          <div className="md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:grid-cols-1">
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="Institution Name"
                className="block text-lg font-medium mt-4"
              >
                Institution Name
              </label>
              <input
                id="institution"
                name="institutionName"
                type="text"
                value={form.institutionName}
                onChange={handleFormChange}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="class" className="block text-lg font-medium mt-4">
                Class
              </label>
              <input
                id="class"
                name="class"
                type="text"
                value={form.class}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                onChange={handleFormChange}
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Last Year Percentage
              </label>
              <input
                id="previous year percentage"
                type="text"
                name="previousYearPercentage"
                onChange={handleFormChange}
                value={form.previousYearPercentage}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Aadhaar Number
              </label>
              <input
                id="aadhar number"
                type="text"
                name="aadhaarNumber"
                onChange={handleFormChange}
                value={form.aadhaarNumber}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
          </div>
        </div>

        {/* Second half */}
        <h3 className="font-bold text-2xl text-center mt-5">Family Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 p-4 lg:px-28 justify-center">
          {currentStudent?.mother?.name !== "" && (
            <>
              <div className="col-span-2">
                <label
                  htmlFor="motherDetails"
                  className="block text-lg font-semibold mt-4 text-center pt-6"
                >
                  Mother Details
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12">
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="motherName"
                      className="block text-lg font-medium mt-4"
                    >
                      Name
                    </label>
                    <input
                      id="motherName"
                      name="motherName"
                      type="text"
                      value={form.motherName}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="motherOccupation"
                      className="block text-lg font-medium mt-4"
                    >
                      Occupation
                    </label>
                    <input
                      id="motherOccupation"
                      name="motherOccupation"
                      type="text"
                      value={form.motherOccupation}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="mother contact number"
                      className="block text-lg font-medium mt-4"
                    >
                      Contact
                    </label>
                    <input
                      id="motherContact"
                      name="motherContact"
                      type="text"
                      value={form.motherContact}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {currentStudent?.father?.name !== "" && (
            <>
              <div className="col-span-2">
                <label
                  htmlFor="fatherDetails"
                  className="block text-lg font-semibold mt-4 text-center pt-6"
                >
                  Father Details
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12">
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="fatherName"
                      className="block text-lg font-medium mt-4"
                    >
                      Name
                    </label>
                    <input
                      id="fatherName"
                      name="fatherName"
                      type="text"
                      value={form.fatherName}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="fatherOccupation"
                      className="block text-lg font-medium mt-4"
                    >
                      Occupation
                    </label>
                    <input
                      id="fatherOccupation"
                      name="fatherOccupation"
                      type="text"
                      value={form.fatherOccupation}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="father contact number"
                      className="block text-lg font-medium mt-4"
                    >
                      Contact
                    </label>
                    <input
                      id="fatherContact"
                      name="fatherContact"
                      type="text"
                      value={form.fatherContact}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {currentStudent?.guardian?.name !== "" && (
            <>
              <div className="col-span-2">
                <label
                  htmlFor="guardianDetails"
                  className="block text-lg font-semibold mt-4 text-center pt-6"
                >
                  Guardian Details
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12">
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="guardianName"
                      className="block text-lg font-medium mt-4"
                    >
                      Name
                    </label>
                    <input
                      id="guardianName"
                      name="guardianName"
                      type="text"
                      value={form.guardianName}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="guardianOccupation"
                      className="block text-lg font-medium mt-4"
                    >
                      Occupation
                    </label>
                    <input
                      id="guardianOccupation"
                      name="guardianOccupation"
                      type="text"
                      value={form.guardianOccupation}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="guardian contact number"
                      className="block text-lg font-medium mt-4"
                    >
                      Contact
                    </label>
                    <input
                      id="guardianContact"
                      name="guardianContact"
                      type="text"
                      value={form.guardianContact}
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Documents */}
        <div className="mt-8 lg:px-8">
          <h3 className="font-bold text-2xl text-center">Documents</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="aadhaar card"
                className="block text-lg font-medium mt-4"
              >
                Aadhar Card
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="aadharcard"
                type="file"
                onChange={handleFileUpload}
                name="aadhaarCard"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="institutionID"
                className="block text-lg font-medium mt-4"
              >
                Institution ID
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="idcard"
                type="file"
                onChange={handleFileUpload}
                name="institutionId"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="rationcard doc"
                className="block text-lg font-medium mt-4"
              >
                Income Proof
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="income card"
                type="file"
                onChange={handleFileUpload}
                name="incomeDocument"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="previous Academic Report"
                className="block text-lg font-medium mt-4"
              >
                Previous Academic Report
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="academicReport"
                type="file"
                onChange={handleFileUpload}
                name="academicReport"
              />
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="Save Details"
          value="Save Details"
          className="absolute bg-blue-500 p-4 rounded-lg text-white font-bold left-1/2 -translate-x-1/2 mt-5 cursor-pointer"
        />
      </form>
      <BackDrop open={isLoading} />
      <ToastContainer />
    </div>
  );
};

export default EditStudent;
