import React, { useState } from "react";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { states, branches } from "./Data";
import { useSelector, useDispatch } from "react-redux";
import {
  uploadStudentDocument,
  uploadStudentPhoto,
} from "../../features/admin/adminSlice";
import { registerStudent } from "../../features/admin/adminSlice";
import { useNavigate } from "react-router-dom";
import { MdAddPhotoAlternate } from "react-icons/md";

const CreateStudent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [branchNames, setBranchNames] = useState([]);
  const [relation, setRelation] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState("");
  const fileInputRef = useRef(null);
  const { isLoading } = useSelector((store) => store["admin"]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    const response = await dispatch(uploadStudentPhoto(file));
    console.log("Response : ", response);
    setForm({ ...form, [event.target.name]: response.payload.url });
    console.log("Form : ", form);
  };

  const handleDocumentUpload = async (event) => {
    const file = event.target.files[0];
    const response = await dispatch(uploadStudentDocument(file));
    setForm({ ...form, [event.target.name]: response.payload.url });
    console.log("Form : ", form);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setBranchNames(branches[e.target.value]);
  };

  const [form, setForm] = useState({
    name: "",
    dateOfBirth: "",
    gender: "",
    category: "poor student",
    state: "",
    branch: "",
    institutionName: "",
    class: "",
    address:"",
    motherName: "",
    motherOccupation: "",
    motherContact: "",
    fatherName: "",
    fatherOccupation: "",
    fatherContact: "",
    guardianName: "",
    guardianOccupation: "",
    guardianContact: "",
    aadhaarNumber: "",
    aadhaarCard: "",
    institutionId: "",
    incomeDocument: "",
    image: "",
    previousYearPercentage: "",
    previousYearMarksDocument: "",
  });

  const handleFormChange = async (e) => {
    console.log(e.target.name + "  " + e.target.value);
    await setForm({ ...form, [e.target.name]: e.target.value });
  };

  const prepareFormData = (form) => {
    const data = {
      name: form.name,
      image: form.image,
      aadhaar: form.aadhaarNumber,
      dateOfBirth: form.dateOfBirth,
      gender: form.gender,
      mother: {
        name: form.motherName,
        occupation: form.motherOccupation,
        contact: form.motherContact,
      },
      father: {
        name: form.fatherName,
        occupation: form.fatherOccupation,
        contact: form.fatherContact,
      },
      guardian: {
        name: form.guardianName,
        occupation: form.guardianOccupation,
        contact: form.guardianContact,
      },
      category: form.category,
      education: {
        institution: form.institutionName,
        class: form.class,
      },
      previousYearMarksDocument: form.previousYearMarksDocument,
      documents: [
        {
          name: "aadhaarCard",
          url: form.aadhaarCard,
        },
        {
          name: "institutionId",
          url: form.institutionId,
        },
        {
          name: "incomeDocument",
          url: form.incomeDocument,
        },
      ],
      previousYearPercentage: form.previousYearPercentage,
      address:form.address
    };
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = prepareFormData(form);
    console.log("Form Data", form);
    console.log("Prepared Data : ", data);
    const response = await dispatch(registerStudent(data));
    if (response.payload.status === "success") {
      setTimeout(() => {
        navigate("/admin/students", { replace: true });
      }, 1000);
    }
  };

  return (
    <div className="py-24">
      {/* First half */}
      <form onSubmit={handleSubmit}>
        <h3 className="font-bold text-2xl text-center">Student Details</h3>
        <div className="grid grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {/*Image div  */}
          <div className="flex justify-center items-center">
            {form.image === "" ? (
              <div
                name="image"
                className="rounded-full relative bg-blue-500 h-52 w-52 cursor-pointer"
                onClick={handleClick}
              >
                <MdAddPhotoAlternate
                  size={55}
                  className="absolute text-white top-20 left-20"
                />
              </div>
            ) : (
              <img
                src={form.image}
                alt="Upload file"
                name="image"
                onClick={handleClick}
                className="rounded-full h-52 w-52 cursor-pointer"
              />
            )}
            <input
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png"
              onChange={handlePhotoUpload}
              ref={fileInputRef}
              className="hidden"
            />
          </div>

          {/* Personal Details */}
          <div className="">
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Name
              </label>
              <input
                id="name"
                type="text"
                name="name"
                onChange={handleFormChange}
                value={form.name}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="dob" className="block text-lg font-medium mt-4">
                Date of Birth
              </label>
              <input
                id="dob"
                type="date"
                name="dateOfBirth"
                value={form.dateOfBirth}
                onChange={handleFormChange}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="gender"
                className="block text-lg font-medium mt-4"
              >
                Gender
              </label>
              <select
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                name="gender"
                onChange={handleFormChange}
                defaultValue={"Select Gender"}
              >
                <option value="Select Gender" disabled>
                  -- SELECT --
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="category"
                className="block text-lg font-medium mt-4"
              >
                Category
              </label>
              <select
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                name="category"
                onChange={handleFormChange}
                defaultValue={"Select Category"}
              >
                <option value="Select Category" disabled>
                  -- SELECT --
                </option>
                <option value="poor student">Poor Student</option>
                <option value="orphan">Orphan</option>
                <option value="semi orphan">Semi Orphan</option>
              </select>
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="address"
                className="block text-lg font-medium mt-4"
              >
                Address
              </label>
              <textarea
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3 bg-white"
                name="address"
                rows="4"
                onChange={handleFormChange}
                value={form.address}
              />
            </div>
          </div>

          {/* educational Details */}
          <div className="md:col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:grid-cols-1">
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="state" className="block text-lg font-medium mt-4">
                State
              </label>
              <select
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                name="state"
                onChange={(e) => {
                  handleStateChange(e);
                  handleFormChange(e);
                }}
                defaultValue={"Select State"}
              >
                <option value="Select State" disabled>
                  -- SELECT --
                </option>
                {states.map((stateName, index) => {
                  return (
                    <option value={stateName} key={index}>
                      {stateName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="branch"
                className="block text-lg font-medium mt-4"
              >
                Branch
              </label>
              <select
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                name="branch"
                onChange={handleFormChange}
                defaultValue={"Select Branch"}
              >
                <option value="Select Branch" disabled>
                  -- SELECT --
                </option>
                {branchNames.map((branchName, index) => {
                  return (
                    <option value={branchName} key={index}>
                      {branchName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="Institution Name"
                className="block text-lg font-medium mt-4"
              >
                Institution Name
              </label>
              <input
                id="institution"
                name="institutionName"
                type="text"
                onChange={handleFormChange}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="class" className="block text-lg font-medium mt-4">
                Class
              </label>
              <input
                id="class"
                name="class"
                type="text"
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
                onChange={handleFormChange}
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Previous Year Percentage
              </label>
              <input
                id="previous year percentage"
                type="text"
                name="previousYearPercentage"
                onChange={handleFormChange}
                value={form.previousYearPercentage}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label htmlFor="name" className="block text-lg font-medium mt-4">
                Aadhaar Number
              </label>
              <input
                id="aadhar number"
                type="text"
                name="aadhaarNumber"
                onChange={handleFormChange}
                value={form.aadhaarNumber}
                className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              />
            </div>
          </div>
        </div>

        {/* Second half */}
        <h3 className="font-bold text-2xl text-center mt-5">Family Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 p-4 lg:px-28 justify-center">
          {/* family Details */}

          {/* Poor student */}
          {form.category === "poor student" && (
            <>
              <div className="">
                <label
                  htmlFor="motherDetails"
                  className="block text-lg font-semibold mt-4 text-center pt-6"
                >
                  Mother Details
                </label>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-12">
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="motherName"
                      className="block text-lg font-medium mt-4"
                    >
                      Name
                    </label>
                    <input
                      id="motherName"
                      name="motherName"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="mother occupation"
                      className="block text-lg font-medium mt-4"
                    >
                      Occupation
                    </label>
                    <input
                      id="motherOccupation"
                      name="motherOccupation"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="mother contact number"
                      className="block text-lg font-medium mt-4"
                    >
                      Contact
                    </label>
                    <input
                      id="motherContact"
                      name="motherContact"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="father details"
                  className="block text-lg font-semibold mt-4 text-center pt-6"
                >
                  Father Details
                </label>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-12">
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="father name"
                      className="block text-lg font-medium mt-4"
                    >
                      Name
                    </label>
                    <input
                      id="fathername"
                      name="fatherName"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0 ">
                    <label
                      htmlFor="state"
                      className="block text-lg font-medium mt-4"
                    >
                      Occupation
                    </label>
                    <input
                      id="occupation"
                      name="fatherOccupation"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="flex flex-col px-10 lg:px-0">
                    <label
                      htmlFor="father contact number"
                      className="block text-lg font-medium mt-4"
                    >
                      Contact
                    </label>
                    <input
                      id="contact"
                      name="fatherContact"
                      type="text"
                      className="border-gray-400 border-2 rounded-md p-2"
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Orphan student */}
          {form.category === "orphan" && (
            <div className="col-span-2">
              <label
                htmlFor="guardian details"
                className="block text-lg font-semibold mt-4 text-center"
              >
                Guardian Details
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-12">
                <div className="flex flex-col px-10 lg:px-0">
                  <label
                    htmlFor="guardian name"
                    className="block text-lg font-medium mt-4"
                  >
                    Name
                  </label>
                  <input
                    id="guardianName"
                    name="guardianName"
                    type="text"
                    className="border-gray-400 border-2 rounded-md p-2"
                    onChange={handleFormChange}
                  />
                </div>
                <div className="flex flex-col px-10 lg:px-0">
                  <label
                    htmlFor="guardianOccupation"
                    className="block text-lg font-medium mt-4"
                  >
                    Occupation
                  </label>
                  <input
                    id="guardianOccupation"
                    name="guardianOccupation"
                    type="text"
                    className="border-gray-400 border-2 rounded-md p-2"
                    onChange={handleFormChange}
                  />
                </div>
                <div className="flex flex-col px-10 lg:px-0">
                  <label
                    htmlFor="caretaker contact Number"
                    className="block text-lg font-medium mt-4"
                  >
                    Contact
                  </label>
                  <input
                    id="guardianContact"
                    name="guardianContact"
                    type="text"
                    className="border-gray-400 border-2 rounded-md p-2"
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
          )}

          {/* semi orphan */}
          {form.category === "semi orphan" && (
            <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:gap-x-8">
              <div className="flex flex-col px-10 lg:px-0">
                <label
                  htmlFor="relation to student"
                  className="block text-lg font-medium mt-4"
                >
                  Relation
                </label>
                <select
                  className="border-gray-400 border-2 rounded-md p-2"
                  name="branch"
                  onChange={(e) => setRelation(e.target.value)}
                  defaultValue={"Select Relation"}
                >
                  <option value="Select Relation" disabled>
                    select relation
                  </option>
                  <option value="mother">Mother</option>
                  <option value="father">Father</option>
                </select>
              </div>

              <div className="flex flex-col px-10 lg:px-0">
                <label
                  htmlFor="relation name"
                  className="block text-lg font-medium mt-4"
                >
                  Name
                </label>
                <input
                  id={relation + "Name"}
                  name={relation + "Name"}
                  type="text"
                  className="border-gray-400 border-2 rounded-md p-2"
                  onChange={handleFormChange}
                />
              </div>
              <div className="flex flex-col px-10 lg:px-0">
                <label
                  htmlFor="relation occupation"
                  className="block text-lg font-medium mt-4"
                >
                  Occupation
                </label>
                <input
                  id={relation + "Occupation"}
                  name={relation + "Occupation"}
                  type="text"
                  className="border-gray-400 border-2 rounded-md p-2"
                  onChange={handleFormChange}
                />
              </div>
              <div className="flex flex-col px-10 lg:px-0">
                <label
                  htmlFor="caretaker contact Number"
                  className="block text-lg font-medium mt-4"
                >
                  Contact
                </label>
                <input
                  id={relation + "Contact"}
                  name={relation + "Contact"}
                  type="text"
                  className="border-gray-400 border-2 rounded-md p-2"
                  onChange={handleFormChange}
                />
              </div>
            </div>
          )}
        </div>

        {/* Documents */}
        <div className="mt-8 lg:px-8">
          <h3 className="font-bold text-2xl text-center">Documents</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="aadhaar card"
                className="block text-lg font-medium mt-4"
              >
                Aadhar Card
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="aadharcard"
                type="file"
                onChange={handleDocumentUpload}
                name="aadhaarCard"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="institutionID"
                className="block text-lg font-medium mt-4"
              >
                Institution ID
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="idcard"
                type="file"
                onChange={handleDocumentUpload}
                name="institutionId"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="rationcard doc"
                className="block text-lg font-medium mt-4"
              >
                Income Proof
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="income card"
                type="file"
                onChange={handleDocumentUpload}
                name="incomeDocument"
              />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
              <label
                htmlFor="previous Academic Report"
                className="block text-lg font-medium mt-4"
              >
                Previous Academic Report
              </label>
              <input
                className="block  text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-4"
                id="academicReport"
                type="file"
                onChange={handleDocumentUpload}
                name="previousYearMarksDocument"
              />
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="Add Student"
          value="Add Student"
          className="absolute bg-blue-500 p-4 rounded-lg text-white font-bold left-1/2 -translate-x-1/2 mt-5 cursor-pointer"
        />
      </form>
      <BackDrop open={isLoading} />
      <ToastContainer />
    </div>
  );
};

export default CreateStudent;
