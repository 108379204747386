import React from "react";
import Backdrop from "@mui/material/Backdrop";
import AddAcademicRecord from "../components/AddAcademicRecord";
const AcademicRecordBackDrop = (props) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
    >
      <AddAcademicRecord />
    </Backdrop>
  );
};

export default AcademicRecordBackDrop;
