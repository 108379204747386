import React from "react";
import AboutImg from "../../assets/aboutus.jpeg";

const AboutUs = () => {
  return (
    <div
      id="about-us"
      class="max-w-7xl mx-auto flex flex-col items-center lg:gap-x-20 lg:gap-y-4 lg:py-16 md:flex-row px-4 py-6"
    >
      <div class="space-y-4 lg:space-y-6 lg:w-1/2">
        <p class="text-lg font-bold lg:text-base">KNOW ABOUT US</p>
        <p class="text-xl font-bold lg:text-3xl text-blueTheme text-justify">
          Many children around us want to pursue extraordinary dreams
        </p>
        <p class="text-sm lg:text-base text-black md:text-lg text-justify">
          However, these children's financial conditions and family support are
          not letting them reach their dreams. Project Vaaradhi deals with such
          children and helps them achieve their goals. The project provides
          financial, emotional, and educational support to poor, orphan, and
          semi-orphan children. The word Vaaradhi reflects the meaning bridge.
          This project builds a gap for poor, orphan, and semi-orphan children
          between the present financial situations of the children and their
          educational needs and emotional support.
        </p>
        <a
          href="https://wearewithyouct.org"
          target="_blank"
          rel="noreferrer"
          class="rounded-[4px] bg-blueTheme text-sm font-semibold text-white shadow-sm lg:w-[146px] lg:h-[51px] w-[120px] h-[35px] text-center flex justify-center items-center"
        >
          Learn More
        </a>
      </div>
      <div class="lg:mt-0 mt-8 lg:w-1/2">
        <img src={AboutImg} alt="Getting Started" className="rounded-lg" />
      </div>
    </div>
  );
};

export default AboutUs;
