import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCount } from "../../features/admin/adminSlice";
import { Sidebar } from "../../components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Events from "../../components/Events";
import "react-toastify/dist/ReactToastify.css";

const Welcome = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.admin);
  const { studentCount } = useSelector((store) => store.admin);
  console.log(studentCount);
  const { caretakerCount } = useSelector((store) => store.admin);
  const { donorCount } = useSelector((store) => store.admin);
  useEffect(() => {
    dispatch(getCount());
  }, [dispatch]);
  const navigate = useNavigate();
  if (!user) {
    return <Navigate to="/" />;
  }
  const handleClick = (e) => {
    navigate(`/admin/${e.target.id}`);
  };
  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-10 py-10 justify-between gap-8 p-4">
          <div
            id="students"
            onClick={handleClick}
            className="box cursor-pointer transition ease-in-out delay-150 md:hover:-translate-y-1 md:hover:scale-105 flex justify-center items-center flex-col bg-blueTheme h-48 rounded-lg p-4"
          >
            <h3 className="text-white font-bold text-xl mb-2">Students</h3>
            <p className="text-white">{studentCount}</p>
          </div>
          <div
            className="box cursor-pointer transition ease-in-out delay-150 md:hover:-translate-y-1 md:hover:scale-105 flex justify-center items-center flex-col bg-blueTheme h-48 rounded-lg p-4"
            id="donors"
            onClick={handleClick}
          >
            <h3 className="text-white font-bold text-xl mb-2">Donors</h3>
            <p className="text-white">{donorCount}</p>
          </div>
          <div
            className="box cursor-pointer transition ease-in-out delay-150 md:hover:-translate-y-1 md:hover:scale-105 duration-300 flex justify-center items-center flex-col bg-blueTheme  h-48 rounded-lg p-4"
            id="caretakers"
            onClick={handleClick}
          >
            <h3 className="text-white font-bold text-xl mb-2">Caretakers</h3>
            <p className="text-white">{caretakerCount}</p>
          </div>
        </div>
        <div className="p-10">
          <h2 className="text-xl font-bold mb-4">Upcoming Events</h2>
            <Events/>
        </div>
      </section>
      <ToastContainer />
    </section>
  );
};

export default Welcome;
