import React, { useEffect } from "react";
import Images from "./Images";
import { useDispatch, useSelector } from "react-redux";
import {  getActivityDonor } from "../features/donor/donorSlice";
import BackDrop from "../utils/BackDrop";
import { Link, useParams } from "react-router-dom";
import { getActivityAdmin } from "../features/admin/adminSlice";
import { getActivityCaretaker } from "../features/caretaker/caretakerSlice";

const Activity = ({ userType }) => {
  const dispatch = useDispatch();
  const { activityId } = useParams();
  const { activityPhotos } = useSelector((state) => state[userType]);
  const { currentActivity, isLoading } = useSelector((store) => store[userType]);
  useEffect(() => {
      if(userType === "admin")
      {
        dispatch(getActivityAdmin({id:activityId}));
      }
      else if(userType === "caretaker")
      {
        dispatch(getActivityCaretaker({id:activityId}));
      }
      else{
        dispatch(getActivityDonor({id:activityId}));
      }
  }, [dispatch,activityId,userType]);

  return (
    <section className="pt-16 pb-4">
      <h1 className="mt-10 text-center text-3xl">Activity</h1>
      <div className="flex flex-col gap-y-4">
        <div className="bg-white w-4/5 gap-y-4 rounded-xl shadow-lg mt-8 py-8 px-10 md:px-16 mb-4 m-auto hover:shadow-xl">
          <h1 className="font-bold mb-2">Title</h1>
          <p className="mb-5">{currentActivity?.title}</p>
          <h1 className="font-bold mb-2">Description</h1>
          <p>{currentActivity?.description}</p>
        </div>
        <div className="w-4/5 m-auto  text-2xl p-3">
          <h1 className="mb-6 font-bold">Images</h1>
          <Images photos={activityPhotos} />
        </div>
        {
          userType === "caretaker" && <div className="flex items-center justify-center">
          <button type="button" className="bg-blue-500 rounded-lg cursor-pointer p-4 text-white font-bold">
            <Link to={`/${userType}/activity/edit/${activityId}`}>Edit Activity</Link>
          </button>
        </div>
        }
      </div>
      <BackDrop open={isLoading}/>
    </section>
  );
};

export default Activity;
