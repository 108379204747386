import React, { useEffect } from "react";
import { SplideSlide, Splide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { MdOutlineAdd } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStudentActivitiesAdmin } from "../features/admin/adminSlice";
import { getStudentActivitiesCaretaker } from "../features/caretaker/caretakerSlice";
import BackDrop from "../utils/BackDrop";

const Activities = ({ userType }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { studentActivities, isLoading } = useSelector(
    (store) => store[userType]
  );
  const splideOptions = {
    perPage: 3,
    perMove: 1,
    type: "slide",
    rewind: true,
    keyboard: "global",
    gap: "1rem",
    pagination: false,
    padding: "2rem",
    breakpoints: {
      1024: { perPage: 3 },
      992: { perPage: 3 },
      768: { perPage: 2 },
      480: { perPage: 1 },
    },
  };

  useEffect(() => {
    if (userType === "caretaker")
      dispatch(getStudentActivitiesCaretaker({ id }));
    if (userType === "admin") dispatch(getStudentActivitiesAdmin({ id }));
  }, [id, dispatch, userType]);

  return (
    <div className="w-full py-12 px-4 md:px-16">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl md:text-3xl  font-medium capitalize">
          Activities
        </h2>
        {(userType === "caretaker" || userType === "admin") && (
          <Link
            to="./addActivity"
            relative="path"
            className="flex items-center text-white bg-blueTheme p-1 md:p-2 rounded-md"
          >
            <MdOutlineAdd /> Add
          </Link>
        )}
      </div>
      {studentActivities.length === 0 ? (
        <div className="text-xl font-medium capitalize text-center">
          No activities
        </div>
      ) : (
        <div className="">
          <Splide options={splideOptions}>
            {studentActivities &&
              [...studentActivities]
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((activity) => (
                  <SplideSlide key={activity._id} className="mb-0.5">
                    <div className="grid items-center bg-white gap-4 pb-2 rounded-lg shadow-lg shadow-slate-200 ring-1 ring-slate-200">
                      <div className="w-auto h-44 flex items-center justify-center p-2">
                        <img
                          className="w-full h-full object-contain rounded-md"
                          src={activity.photos[0]}
                          alt="activity-img"
                        />
                      </div>
                      <div className="grid items-center px-4">
                        <h1 className="text-base font-semibold">
                          {activity.title}
                        </h1>
                        <p className="text-sm text-justify lg:text-sm truncate">
                          {activity.description}
                        </p>
                      </div>
                      <div className="flex justify-center items-center p-3 bg-blueTheme text-white rounded-lg m-3">
                        <Link
                          to={`/${userType}/activity/${activity._id}`}
                          className="w-full text-center"
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
          </Splide>
        </div>
      )}
      <BackDrop open={isLoading} />
    </div>
  );
};

export default Activities;
