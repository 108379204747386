import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/url";
import { toast } from "react-hot-toast";

const user = localStorage.getItem("user");
const token = localStorage.getItem("token");

const initialState = {
  user: user ? JSON.parse(user) : null,
  token: token,
  students: [],
  isLoading: false,
  currentStudent: {},
  studentActivities: [],
  currentActivity: {},
  spendings: [],
  academicRecords: [],
  activityPhotos: [],
  message: "",
};

export const donorLogin = createAsyncThunk(
  "donor/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/donor/login`, payload);
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudents = createAsyncThunk(
  "donor/students",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/donor/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getStudent = createAsyncThunk(
  "donor/students/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/donor/students/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityDonor = createAsyncThunk(
  "donor/activity/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/donor/activity/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityPhotos = createAsyncThunk(
  "api/donor/activity/:id",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/donor/activity/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "api/donor/profile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/donor/profile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// To be done later
export const updatePassword = createAsyncThunk(
  "/api/donor/password",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/api/donor/password`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSpendingsDonor = createAsyncThunk(
  "students/:studentId/spending(Get)donor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/donor/students/${payload.id}/spending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAcademicRecordsDonor = createAsyncThunk(
  "academicRecordsDonor",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/donor/students/${payload.id}/academicRecord`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const donorSlice = createSlice({
  name: "donor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(donorLogin.pending, (state) => {
      state.isLoading = true;
      state.message = "Processing";
    });
    builder.addCase(donorLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { donorData, token } = payload;
      localStorage.setItem("user", JSON.stringify(donorData));
      localStorage.setItem("token", token);
      state.user = donorData;
      state.token = token;
      state.message = payload.message;
      toast.success("Login Successful ");
    });
    builder.addCase(donorLogin.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
      toast.error("Invalid Credentials!");
    });

    builder.addCase(getStudents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { data } = payload;
      state.students = data;
    });

    builder.addCase(getStudents.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(getStudent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStudent.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentStudent = payload.data;
      state.studentActivities = state.currentStudent.activities;
    });

    builder.addCase(getStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(getActivityDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getActivityDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.currentActivity = payload.data;
      state.activityPhotos = state.currentActivity.photos;
    });

    builder.addCase(getActivityDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(editProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const updatedDetails = payload.updatedDonor;
      localStorage.setItem("user", JSON.stringify(updatedDetails));
      state.message = payload.message;
      state.user = updatedDetails;
      toast.success("Details Updated !");
    });

    builder.addCase(editProfile.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
      toast.error("Failed to update Details !");
    });

    builder.addCase(updatePassword.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
    });

    builder.addCase(updatePassword.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.message = payload.message;
    });

    builder.addCase(getSpendingsDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSpendingsDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.spendings = payload.data;
    });

    builder.addCase(getSpendingsDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getAcademicRecordsDonor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAcademicRecordsDonor.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.academicRecords = payload.academicRecords;
    });

    builder.addCase(getAcademicRecordsDonor.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });
  },
});

export default donorSlice.reducer;
