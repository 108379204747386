/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import {
  registerDonor,
  uploadDonorPhoto,
} from "../../features/admin/adminSlice";
import { states, branches } from "./Data";
import { TextField } from "@mui/material";
import BackDrop from "../../utils/BackDrop";
import { toast } from "react-hot-toast";
import { MdAddPhotoAlternate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const NewDonor = () => {
  const navigate = useNavigate();
  const { isLoading } = useSelector((store) => store["admin"]);
  const [state, setState] = useState("");
  const [branchNames, setBranchNames] = useState([]);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    image: "",
    dateOfBirth: "",
    email: "",
    password: "",
    mobile: "",
    address: "",
    dateOfJoin: "",
    occupation: "",
    pan: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    console.log("Donor data", form);
    e.preventDefault();
    if (confirmPassword !== form.password) {
      toast.error("Passwords doesnot match");
    } else {
      console.log("Submitted Data : ", form);
      const response = await dispatch(registerDonor(form));
      if (response.payload.status === "success") {
        setTimeout(() => {
          navigate(-1, { reset: true });
        }, 1000);
      }
    }
  };

  const handleFileUpload = async (event) => {
    console.log("name : ", event.target.name);
    const file = event.target.files[0];
    const response = await dispatch(uploadDonorPhoto(file));
    setForm({ ...form, [event.target.name]: response.payload.url });
  };

  const handleFormChange = async (e) => {
    console.log(e.target.name + "  " + e.target.value);
    await setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setBranchNames(branches[e.target.value]);
  };

  return (
    <div className="py-24">
      {/* First half */}
      <form onSubmit={handleSubmit}>
        <h3 className="font-bold text-2xl text-center">Donor Details</h3>
        {/* overall div */}
        <div className="grid grid-cols-1 p-4 md:grid-cols-3 lg:grid-cols-3 gap-3">
          {/* image div */}
          <div className="flex justify-center items-center">
            {form.image === "" ? (
              <div
                name="image"
                className="rounded-full relative bg-blue-500 h-52 w-52 cursor-pointer"
                onClick={handleClick}
              >
                <MdAddPhotoAlternate
                  size={55}
                  className="absolute text-white top-20 left-20"
                />
              </div>
            ) : (
              <img
                src={form.image}
                alt="Upload file"
                name="image"
                onClick={handleClick}
                className="rounded-full h-52 w-52 cursor-pointer"
              />
            )}
            <input
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
            />
          </div>
          {/* Personal Details div */}
          <div className="">
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="name" className="block text-lg font-medium mt-4">
              Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              onChange={handleFormChange}
              value={form.name}
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="dob" className="block text-lg font-medium mt-4">
              Date of Birth
            </label>
            <input
              id="dob"
              type="date"
              name="dateOfBirth"
              value={form.dateOfBirth}
              onChange={handleFormChange}
              className="border-gray-400 border-2 rounded-md p-2 sm:w-full lg:w-2/3"
            />
            </div>

            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="gender" className="block text-lg font-medium mt-4">
              Gender
            </label>
            <select
              className="border-gray-400 border-2 rounded-md p-2  lg:w-2/3"
              name="gender"
              onChange={handleFormChange}
              defaultValue={"Select Gender"}
            >
              <option value="Select Gender" disabled>
                -- SELECT --
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="name" className="block text-lg font-medium mt-4">
              Occupation
            </label>
            <input
              id="occupation"
              type="text"
              name="occupation"
              onChange={handleFormChange}
              value={form.occupation}
              className="border-gray-400 border-2 rounded-md p-2  lg:w-2/3"
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="email" className="block text-lg font-medium mt-4">
              Email ID :
            </label>
            <input
              type="email"
              name="email"
              value={form.email}
              className="border-gray-400 border-2 rounded-md p-2  lg:w-2/3"
              onChange={handleFormChange}
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="mobile" className="block text-lg font-medium mt-4">
              Contact No:{" "}
            </label>
            <input
              type="text"
              name="mobile"
              value={form.mobile}
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              onChange={handleFormChange}
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="pan" className="block text-lg font-medium mt-4">
              Pan No:{" "}
            </label>
            <input
              type="text"
              name="pan"
              value={form.pan}
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              onChange={handleFormChange}
            />
            </div>
          </div>

          {/* other details div */}
          <div>
          <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="state" className="block text-lg font-medium mt-4">
              State
            </label>
            <select
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              name="state"
              onChange={(e) => {
                handleStateChange(e);
                handleFormChange(e);
              }}
              defaultValue={"Select State"}
            >
              <option value="Select State" disabled>
                -- SELECT --
              </option>
              {states.map((stateName, index) => {
                return (
                  <option value={stateName} key={index}>
                    {stateName}
                  </option>
                );
              })}
            </select>
              </div>
              <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="branch" className="block text-lg font-medium mt-4">
              Branch
            </label>
            <select
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              name="branch"
              onChange={handleFormChange}
              defaultValue={"Select Branch"}
            >
              <option value="Select Branch" disabled>
                -- SELECT --
              </option>
              {branchNames.map((branchName, index) => {
                return (
                  <option value={branchName} key={index}>
                    {branchName}
                  </option>
                );
              })}
            </select>
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label
              htmlFor="date of joining"
              className="block text-lg font-medium mt-4"
            >
              Date of Joining
            </label>
            <input
              type="date"
              name="dateOfJoin"
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              onChange={handleFormChange}
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label htmlFor="address" className="block text-lg font-medium mt-4">
              Address
            </label>
            <TextField
              className="border-gray-400 rounded-md p-2 lg:w-2/3 bg-white"
              name="address"
              onChange={handleFormChange}
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label
              htmlFor="password"
              className="block text-lg font-medium mt-4"
            >
              Password{" "}
            </label>
            <input
              type="password"
              name="password"
              value={form.password}
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              onChange={handleFormChange}
            />
            </div>
            <div className="flex flex-col px-10 lg:px-0">
            <label
              htmlFor="confirm password"
              className="block text-lg font-medium mt-4"
            >
              Confirm Password{" "}
            </label>
            <input
              type="password"
              name="confrimPassword"
              value={confirmPassword}
              className="border-gray-400 border-2 rounded-md p-2 lg:w-2/3"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="submit"
          value="Add Donor"
          className="p-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-700 cursor-pointer absolute left-1/2 -translate-x-1/2 mt-5"
        />
      </form>
      <BackDrop open={isLoading} />
    </div>
  );
};

export default NewDonor;
