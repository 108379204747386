export const states = [
    "Andhra Pradesh",
    "Tamil Nadu",
    "Telangana",
  ];
  
export const branches = {
    "Andhra Pradesh" : ['Guntur','Vijayawada'],
    "Telangana" : ['Hyderabad'],
    "Tamil Nadu" : ['Chennai']
}