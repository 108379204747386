import React from "react";
import N1 from "../../assets/n1.jpeg";
import N2 from "../../assets/n2.jpg";
import N3 from "../../assets/n3.jpg";
import N4 from "../../assets/n4.jpg";
import N5 from "../../assets/n5.jpeg";
import N6 from "../../assets/n6.JPG";
import N7 from "../../assets/n7.jpg";
import N8 from "../../assets/n8.jpg";

const images = [N1, N2, N4, N3, N5, N6, N7, N8];

const Gallery = () => {
  return (
    <div id="media" className="mx-auto max-w-7xl lg:px-0 lg:py-16 px-4 py-6">
      <div className="mx-auto max-w-3xl md:text-center">
        <p className="text-xl font-bold lg:text-3xl text-blueTheme text-center">
          We are creating a place where children with special needs can thrive
        </p>
      </div>
      <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="mx-auto w-[300px] rounded-md border border-blueTheme shadow-md"
          >
            <img
              src={image}
              alt="Gallery"
              className="h-full w-full rounded-t-md object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
