import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getCaretaker,
  removeCaretaker,
  makeCaretakerActive,
  makeCaretakerInactive,
} from "../../features/admin/adminSlice";
import BackDrop from "../../utils/BackDrop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentsContainer from "../../components/StudentsContainer";
import { formatDate } from "../../utils/formatDate";
import { useNavigate } from "react-router-dom";
const Caretaker = ({ userType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { currentCaretaker } = useSelector((store) => store[userType]);
  const { isLoading } = useSelector((store) => store[userType]);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userType === "admin") {
      dispatch(getCaretaker({ id }));
    }
  }, [dispatch, id, userType, pathname]);

  const dob = currentCaretaker.dateOfBirth;
  const date = new Date(dob);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Note: January is 0 in JavaScript, so we need to add 1
  const year = date.getFullYear();
  const dateOfBirth = day + "-" + month + "-" + year;

  const handleRemoveCaretaker = async () => {
    const response = await dispatch(
      removeCaretaker({ id: currentCaretaker?._id })
    );
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };

  const handleCaretakerInactive = async () => {
    const response = await dispatch(
      makeCaretakerInactive({ id: currentCaretaker?._id })
    );
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };

  const handleCaretakerStatus = async () => {
    const response = await dispatch(
      makeCaretakerActive({ id: currentCaretaker?._id })
    );
    if (response.payload.status === "success") {
      navigate(-1, { replace: true });
    }
  };
  return (
    <div className="py-28">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 items-center">
        <div className="flex flex-col items-center justify-center gap-4">
          <img
            src={currentCaretaker.image}
            alt="caretaker"
            className="rounded-full h-52 w-52"
          />
          <div className="flex flex-col gap-2">
            {currentCaretaker?.isActive ? (
              <button
                type="button"
                onClick={handleCaretakerInactive}
                className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-yellow-400 hover:bg-yellow-500 focus:outline-none shadow-md"
              >
                Make Inactive
              </button>
            ) : (
              <button
                type="button"
                onClick={handleCaretakerStatus}
                className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-blue-500 hover:bg-blue-600 focus:outline-none shadow-md"
              >
                Make Active
              </button>
            )}
            <button
              type="button"
              onClick={handleRemoveCaretaker}
              className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white  bg-red-500 hover:bg-red-600 focus:outline-none shadow-md"
            >
              Remove Caretaker
            </button>
          </div>
        </div>
        <div className="p-4">
          <h3 className="font-bold text-start text-2xl mb-6">
            Caretaker Details
          </h3>
          <div className="flex justify-start flex-row">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center">
                <label
                  htmlFor="caretakerName"
                  className="font-semibold text-xl"
                >
                  Name:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentCaretaker.name}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="caretakerDOB" className="font-semibold text-xl">
                  DOB:&nbsp;
                </label>
                <p className="text-lg font-medium">{dateOfBirth}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="font-semibold text-xl">
                  Email:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentCaretaker.email}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="mobile" className="font-semibold text-xl">
                  Mobile:&nbsp;
                </label>
                <p className="text-lg font-medium">{currentCaretaker.mobile}</p>
              </div>
              <div className="flex items-center">
                <label htmlFor="caretakerDOB" className="font-semibold text-xl">
                  Joining Date:&nbsp;
                </label>
                <p className="text-lg font-medium">
                  {formatDate(currentCaretaker.dateOfJoin)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="flex items-center mt-4 md:mt-16 justify-center">
        <div className="w-full">
          <h2 className="font-bold text-center text-2xl">Students</h2>
          <StudentsContainer userType={"admin"} />
        </div>
      </section>
      <ToastContainer />
      <BackDrop open={isLoading}></BackDrop>
    </div>
  );
};

export default Caretaker;
