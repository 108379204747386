import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "../../components";
import jwt_decode from "jwt-decode";
const ProtectedLayout = () => {
  const { token, user } = useSelector((state) => state["caretaker"]);
  var decoded = jwt_decode(token);
  const role = decoded.role;
  const location = useLocation();
  const navigate = useNavigate();
  if (!user) {
    navigate("/caretaker/login", { replace: true });
  }
  return (
    <main className="bg-slate-100 h-screen">
      <Navbar role="caretaker" />
      {role === "caretaker" ? (
        <section className="bg-slate-100">
          <Outlet />
        </section>
      ) : (
        <Navigate to={"/unauthorized"} state={{ from: location }} replace />
      )}
    </main>
  );
};

export default ProtectedLayout;
