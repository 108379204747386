import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/url";
import { toast } from "react-hot-toast";

const initialState = {
  spendings: [],
  academicRecords: [],
  isLoading: false,
  uploading: false,
};

export const getAcademicRecords = createAsyncThunk(
  "students/:studentId/academicRecord(Get)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/students/${payload.id}/academicRecord`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSpendings = createAsyncThunk(
  "students/:studentId/spending(Get)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/admin/students/${payload.id}/spending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadToCloud = createAsyncThunk(
  "/upload(post-request)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/studentActivities`,
        payload.formDataPhotos,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadGroupActivity = createAsyncThunk(
  "upload/studentActivities(group activity)",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/upload/studentActivities`,
        payload.formDataPhotos,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const commonSlice = createSlice({
  name: "common Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpendings.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSpendings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.spendings = payload.data;
    });

    builder.addCase(getSpendings.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(getAcademicRecords.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAcademicRecords.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.academicRecords = payload.academicRecords;
    });

    builder.addCase(getAcademicRecords.rejected, (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadToCloud.pending, (state) => {
      state.uploading = true;
    });

    builder.addCase(uploadToCloud.fulfilled, (state, { payload }) => {
      state.uploading = false;
      toast.success("Upload completed!");
    });

    builder.addCase(uploadToCloud.rejected, (state, { payload }) => {
      state.uploading = false;
      toast.error(payload.message);
    });

    builder.addCase(uploadGroupActivity.pending, (state) => {
      state.uploading = true;
    });

    builder.addCase(uploadGroupActivity.fulfilled, (state, { payload }) => {
      state.uploading = false;
      toast.success("Photos Uploaded!");
    });

    builder.addCase(uploadGroupActivity.rejected, (state, { payload }) => {
      state.uploading = false;
      toast.error(payload.message);
    });
  },
});

export default commonSlice.reducer;
