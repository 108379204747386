import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Activity from "./components/Activity";
import Student from "./components/Student";

import {
  Welcome as AdminHome,
  CreateStudent,
  AllStudents,
  AllDonors,
  AdminLayout,
  AllCaretakers,
  Donor,
  Caretaker,
  AddAdmin,
} from "./pages/admin";
import { Welcome as DonorHome } from "./pages/donor";
import {
  Welcome,
  AddActivity,
  ProtectedLayout as CaretakerLayout,
} from "./pages/caretaker";
import { ProtectedLayout as DonorLayout } from "./pages/donor";
import Landing from "./pages/landing/Landing";
import CaretakerProfile from "./pages/caretaker/Profile";
import Profile from "./pages/donor/Profile";
import NewCaretaker from "./pages/admin/NewCaretaker";
import NewDonor from "./pages/admin/NewDonor";
import EditActivity from "./pages/caretaker/EditActivity";
import SpendingBackDrop from "./utils/SpendingBackDrop";
import GroupActivity from "./pages/admin/GroupActivity";
import EditStudent from "./pages/admin/EditStudent";
import AcademicRecordBackDrop from "./utils/AcademicRecordBackDrop";
import AddEvent from "./pages/admin/AddEvent";
import AdminProfile from "./pages/admin/Profile";
import { Toaster } from "react-hot-toast";
import MappingCaretaker from "./pages/admin/MappingCaretaker";
import MappingDonor from "./pages/admin/MappingDonor";
import UnAuthorized from "./components/UnAuthorized";

function App() {
  return (
    <>
      <div>
        <Toaster position="top-right"></Toaster>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/:userType/login" element={<Login />} />

          {/* admin routes */}
          <Route path="/admin/" element={<AdminLayout />}>
            <Route index element={<AdminHome />} />
            <Route path="profile" element={<AdminProfile />} />
            {/* <Route path="login" element={<Login />} /> */}
            {/* <Route path="addAdmin" element={<AddAdmin />} /> */}
            <Route path="students" element={<AllStudents />} />
            <Route
              path="students/:id/spending"
              element={<SpendingBackDrop open={true} />}
            />
            <Route
              path="students/:id/addAcademicRecord"
              element={<AcademicRecordBackDrop open={true} />}
            />
            <Route
              path="students/:id/mapcaretaker"
              element={<MappingCaretaker />}
            />
            <Route path="students/:id/mapdonor" element={<MappingDonor />} />
            <Route
              path="students/:id/addActivity"
              element={<AddActivity userType="admin" />}
            />

            <Route path="students/:id" element={<Student userType="admin" />} />
            <Route
              path="activity/:activityId"
              element={<Activity userType="admin" />}
            />
            <Route path="groupActivity" element={<GroupActivity />} />
            <Route path="addAdmin" element={<AddAdmin />} />
            <Route path="addEvent" element={<AddEvent />} />
            <Route path="createstudent" element={<CreateStudent />} />
            <Route path="newcaretaker" element={<NewCaretaker />} />
            <Route path="newdonor" element={<NewDonor />} />
            <Route path="donors" element={<AllDonors />} />
            <Route path="donors/:id" element={<Donor userType="admin" />} />
            <Route path="caretakers" element={<AllCaretakers />} />
            <Route
              path="caretakers/:id"
              element={<Caretaker userType="admin" />}
            />
            <Route
              path="students/:id/spending"
              element={<SpendingBackDrop open={true} />}
            />
            <Route path="students/:id/edit" element={<EditStudent />}></Route>
          </Route>
          {/* admin routes end */}

          {/* donor routes */}
          <Route path="/donor/" element={<DonorLayout />}>
            <Route index element={<DonorHome />} />
            <Route path="/donor/profile" element={<Profile />} />
            <Route path="students/:id" element={<Student userType="donor" />} />
            <Route
              path="activity/:activityId"
              element={<Activity userType="donor" />}
            />
          </Route>
          {/* donor routes end */}

          {/* caretaker routes */}
          <Route path="/caretaker/" element={<CaretakerLayout />}>
            <Route index element={<Welcome />} />
            <Route path="profile" element={<CaretakerProfile />} />
            <Route
              path="students/:id"
              element={<Student userType="caretaker" />}
            />
            <Route
              path="activity/:activityId"
              element={<Activity userType="caretaker" />}
            />
            <Route
              path="activity/edit/:activityId"
              element={<EditActivity userType="caretaker" />}
            ></Route>
            {/* <Route path="login" element={<Login />} /> */}
            <Route
              path="students/:id"
              element={<Student userType="caretaker" />}
            />
            <Route
              path="students/:id/addActivity"
              element={<AddActivity userType="caretaker" />}
            />
          </Route>
          {/* caretaker routes end */}
          <Route path="/unauthorized" element={<UnAuthorized />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
