import React from "react";
import { Menu, X } from "lucide-react";
import Logo from "../../assets/WAYLogo.png";
import { handleScroll } from "../../utils/handleScroll";
import LoginDropdown from "./LoginDropdown";

const menuItems = [
  {
    name: "Home",
    href: "#home",
  },
  {
    name: "About Us",
    href: "#about-us",
  },
  {
    name: "What We Do",
    href: "#what-we-do",
  },
  {
    name: "Media",
    href: "#media",
  },
  {
    name: "Join Us",
    href: "#join-us",
  },
];

export function VaaradhiNavbar() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="w-full bg-white z-50">
      <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-2 lg:py-[18px] sm:px-6 lg:px-8 h-[87px]">
        <div className="inline-flex items-center space-x-2 cursor-pointer">
          <img
            src={Logo}
            alt="Vaaradhi Logo"
            className="h-8 w-8 lg:h-11 lg:w-11"
          />
          <span className="text-xl lg:text-2xl font-bold">Vaaradhi</span>
        </div>
        <div className="hidden lg:block">
          <ul className="inline-flex space-x-8">
            {menuItems.map((item) => (
              <li key={item.name}>
                <a
                  onClick={handleScroll}
                  href={item.href}
                  className="text-sm font-semibold text-black"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden lg:block">
          <LoginDropdown />
        </div>
        <div className="lg:hidden">
          <Menu onClick={toggleMenu} className="h-6 w-6 cursor-pointer" />
        </div>
        {isMenuOpen && (
          <div className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-1 transition lg:hidden">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pb-6 pt-5">
                <div className="flex items-center justify-between">
                  <div className="inline-flex items-center space-x-2">
                    <img src={Logo} alt="Vaaradhi Logo" className="h-8 w-8" />
                    <span className="font-bold">Vaaradhi</span>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={toggleMenu}
                      className="inline-flex items-center justify-center rounded-md p-2 text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                    >
                      <span className="sr-only">Close menu</span>
                      <X className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-4">
                    {menuItems.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-black"
                      >
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="px-5 py-6">
                <LoginDropdown />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VaaradhiNavbar;
