import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BackDrop from "../utils/BackDrop";

const StudentsContainer = ({ userType }) => {
  const { students } = useSelector((store) => store[userType]);
  const { isLoading } = useSelector((store) => store[userType]);

  return (
    <div className="grid grid-cols-1 my-8 m-auto gap-y-10 md:gap-y-20 md:grid-cols-2 xxl:grid-cols-3 md:px-16">
      {students.map((student) => {
        return (
          <div
            key={student.id}
            className="flex flex-col bg-white m-auto items-center w-64 sm:w-72 xl:w-80 rounded-lg p-7 shadow-lg justify-start hover:shadow-xl transition duration-300 ease-in-out h-full"
          >
            <div>
              <img
                src={student.image || "https://picsum.photos/200"}
                alt="student-img"
                className="md:w-52 md:h-52 w-40 h-40 object-cover rounded-full"
              />
            </div>

            <div className="text-left mt-4 md:mt-6 h-full w-full">
              <h1 className="md:text-lg text-base  font-semibold leading-tight text-gray-800">
                <label
                  htmlFor="student-name"
                  className="font-bold text-black-600"
                >
                  Name:{" "}
                </label>
                <span id="student-name">{student?.name}</span>
              </h1>
              <p className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                <label
                  htmlFor="student-gender"
                  className="font-bold text-grey-600"
                >
                  Gender:{" "}
                </label>
                <span id="student-gender">{student?.gender}</span>
              </p>
              <p className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                <label
                  htmlFor="student-class"
                  className="font-bold text-grey-600"
                >
                  Class:{" "}
                </label>
                <span id="student-class">{student?.education?.class}</span>
              </p>
              <p className="my-1 md:my-2 text-sm md:text-base text-gray-600">
                <label
                  htmlFor="student-institution"
                  className="font-bold text-grey-600"
                >
                  Institution:{" "}
                </label>
                <span id="student-institution">
                  {student.education?.institution}
                </span>
              </p>
            </div>

            <div className="self-start">
              <Link
                to={`/${userType}/students/${student.id}`}
                className="inline-block rounded-lg mt-2 px-4 py-2 text-sm font-semibold  text-white bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600 focus:outline-none shadow-md"
              >
                More details
              </Link>
            </div>
          </div>
        );
      })}
      <BackDrop open={isLoading}></BackDrop>
    </div>
  );
};

export default StudentsContainer;
