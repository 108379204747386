/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { SplideSlide, Splide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Images = ({ photos }) => {
  const splideOptions = {
    perPage: 3,
    perMove: 1,
    type: "slide",
    rewind: true,
    keyboard: "global",
    gap: "1rem",
    pagination: false,
    padding: "2rem",
    breakpoints: {
      1024: { perPage: 3 },
      992: { perPage: 3 },
      768: { perPage: 2 },
      480: { perPage: 1 },
    },
  };

  return (
    <Splide options={splideOptions}>
      {photos &&
        photos.map((photo, index) => (
          <SplideSlide key={index} className="mb-0.5">
            <div className="grid items-center bg-white gap-4 pb-2 rounded-md shadow-lg shadow-slate-200 ring-1 ring-slate-200">
              <div className="w-full h-80 flex items-center justify-center p-2">
                <img
                  className="w-full h-full rounded-md object-contain"
                  src={photo}
                  alt="activity-img"
                />
              </div>
            </div>
          </SplideSlide>
        ))}
    </Splide>
  );
};

export default Images;
