//new file

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { BiRadioCircle } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import BackDrop from "../../utils/BackDrop";
import {
    getDonors,
    assignDonor,
    // removeDonorForStudent,
  } from "../../features/admin/adminSlice";

const MappingDonor = () => {
    const { id } = useParams();
    const navigate = useNavigate();
  
    const dispatch = useDispatch();
    const { donors, isLoading } = useSelector((store) => store.admin);
    const [selectedDonor, setSelectedDonor] = useState("");
  
    useEffect(() => {
      dispatch(getDonors());
    }, [dispatch]);


  const handleAddDonor = async(e) => {
    e.preventDefault();
    console.log("Adding donor...");
    console.log(selectedDonor);
    const response = await dispatch(
      assignDonor({ donorId: selectedDonor, studentId: id })
    );
    console.log(response.payload)
    if (response.payload.status === "success")
    {
      setTimeout(()=>{
        navigate(-1,{replace : true});
      },1000)
    }
  };
  return (
    <div className="pt-24 pb-8 flex flex-col items-center bg-slate-100 justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4 p-6">
        {donors.map((donor) => {
          return (
            <div
              key={donor?._id}
              className="bg-white flex items-center p-4 rounded-md gap-4 cursor-pointer"
              onClick={() => setSelectedDonor(donor?._id)}
            >
              {/* image */}
              <div>
                {selectedDonor === donor?._id ? (
                  <BsCheckCircleFill size={30} className="text-green-500" />
                ) : (
                  <BiRadioCircle size={30} className="" />
                )}
              </div>
              <div className="w-14 h-14">
                <img
                  src={donor?.image}
                  alt={donor?.name}
                  className="rounded-full w-full h-full"
                />
              </div>
              <div>
                <p className="text-sm  lg:text-lg text-black">
                  {donor?.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center gap-x-5 px-8">
        <input
          type="button"
          name="cancel"
          value="Cancel"
          onClick={() => navigate(-1)}
          className="bg-red-500 text-white text-md font-bold w-24 p-3 rounded-md mt-3 hover:bg-red-700 cursor-pointer"
        />
        <input
          type="submit"
          name="submit"
          value="Save"
          onClick={handleAddDonor}
          className="bg-blue-600 text-white text-md font-bold w-24 p-3 rounded-md mt-3 hover:bg-blue-700 cursor-pointer"
        />
      </div>
      <BackDrop open={isLoading} />
    </div>
  );
};

export default MappingDonor;
