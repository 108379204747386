import { configureStore } from "@reduxjs/toolkit";
import donorReducer from "./features/donor/donorSlice";
import adminReducer from "./features/admin/adminSlice";
import caretakerReducer from "./features/caretaker/caretakerSlice";
import commonReducer from "./features/common/commonSlice";
const store = configureStore({
  reducer: {
    donor: donorReducer,
    admin: adminReducer,
    caretaker: caretakerReducer,
    common : commonReducer
  },
});

export default store; 
