import { Sidebar } from "../../components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDonors } from "../../features/admin/adminSlice";
import DonorsContainer from "../../components/DonorsContainer";
import { useNavigate } from "react-router-dom";
const AllDonors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDonors());
  }, [dispatch]);
  return (
    <section>
      <Sidebar />
      <section className="right-side bg-slate-100 pt-16 pb-4 lg:pl-64">
          {/* Students Heading */}
          <div className="flex justify-between items-center mx-auto px-7 md:px-16 xl:px-28 mt-12">
            <h2 className="font-semibold text-2xl text-gray-800">
              Our Donors
            </h2>
            <input
              type="button"
              name="add Student"
              value="Add Donor"
              className="text-white bg-blue-500 font-bold max-w-fit cursor-pointer py-2 px-4 rounded-md md:mr-36"
              onClick={() => navigate("/admin/newdonor")}
              />
          </div>
        <DonorsContainer userType="admin" />
      </section>
    </section>
  );
};

export default AllDonors;
